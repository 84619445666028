import React, { useCallback, useState } from 'react'
import { matchPath, Navigate } from 'react-router-dom'

import { BUTTON_SECONDARY, IconTypes } from '@acre/design-system'

import { MainNavProps, NavContent } from './Nav.helpers'
import { ColouredButton } from './Nav.styles'

const GeneralInsuranceCompareQuotesNav = ({ pathname }: MainNavProps) => {
  const [redirect, setRedirect] = useState<string | undefined>()

  const handleClick = useCallback(() => {
    const match = matchPath(
      {
        path: '/cases/:id/general-insurance-compare-quotes',
      },
      pathname,
    )

    const id = match!.params.id!

    setRedirect(`/cases/${id}/overview`)
  }, [pathname])

  return (
    <>
      {redirect && <Navigate to={redirect} replace />}
      <ColouredButton buttonStyle={BUTTON_SECONDARY} onClick={handleClick}>
        <NavContent iconName={IconTypes.ArrowLeft} copyId="generalInsurance.giQuoting" />
      </ColouredButton>
    </>
  )
}

export default GeneralInsuranceCompareQuotesNav
