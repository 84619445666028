// nfantone/use-validation-schema.js
// https://gist.github.com/nfantone/9ab600760db8774ab4873cb1a3a22f26
import { setIn } from 'final-form'
import type { AnyObject, AnySchema, InferType, Lazy, ValidationError } from 'yup'

// /**
//  * Sets the `innerError.message` in an `errors` object at the key
//  * defined by `innerError.path`.
//  */
const setInError = (errors: Record<string, string>, innerError: ValidationError, messages: string[]) => {
  return setIn(errors, innerError.path ?? '', {
    message: innerError?.message || '',
    messages,
    // We need to capture any parameters returned by yup,
    // such as less-than or greater-than
    ...innerError.params,
  })
}

// /**
//  * Takes a `yup` validation schema and returns a function that expects
//  * a map of values to validate. If the validation passes, the function resolves to `undefined`
//  * (signalling that the values are valid). If the validation doesn't pass, it resolves
//  * to a map of invalid field names to errors.
//  */

export default function <
  S extends AnySchema | Lazy<any>,
  V extends AnyObject = InferType<S>,
  C extends Record<string, unknown> = Record<string, unknown>,
>(schema: S, context?: C) {
  // Removing the "| any" type below will cause the types to work for the validate function, but will
  //   cause the type checks to explodes because our validations having missing properties most of the time
  return function (values: V | any) {
    try {
      schema.validateSync(values, { abortEarly: false, context: { ...(context || {}), ...values } })
    } catch (err) {
      if (err instanceof Error && err?.name === 'ValidationError') {
        return (err as ValidationError).inner.reduce(
          (acc, curr) => setInError(acc, curr, (err as ValidationError).errors),
          {},
        )
      }
    }
    return {}
  }
}
