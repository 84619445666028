import React from 'react'
import { matchPath } from 'react-router-dom'

import { PREFERENCE_MAP } from '@acre/utils'
import { getClientName, MortgageReason, useGetCaseQuery } from '@acre/graphql'
import { IconTypes } from '@acre/design-system'

import { MainNavProps, NavContent } from './Nav.helpers'

// "+n others" is shown if there are more clients
const CLIENTS_TO_SHOW = 3

const ComplianceCaseDetailsNav = ({ pathname }: MainNavProps) => {
  const match = matchPath(
    {
      path: '/compliance/cases/:caseId/*',
    },
    pathname,
  )

  const id = match!.params.caseId!

  const { data, loading, error } = useGetCaseQuery({
    variables: { id },
  })

  const reason = data?.case?.details?.preference_mortgage_reason || MortgageReason.InvalidMortgageReason
  const title = PREFERENCE_MAP[reason]

  const clients = data?.case?.details?.clients || []
  const clientsToShow = clients?.slice(0, CLIENTS_TO_SHOW)

  if (loading || error) {
    return <NavContent iconName={IconTypes.Cases} copyId="cases.title" />
  }

  if (data) {
    return (
      <NavContent id="CaseNavContent" iconName={IconTypes.Cases} copyId={title}>
        {clientsToShow.length > 0 && ' - '}
        {clientsToShow.map((client, index) => {
          const { id } = client
          const clientName = getClientName({ client: client.details })
          let result = null
          if (clientName) {
            result = (
              <span key={id}>
                {/* FRON-3047 Revert refresh operation when moving from case to
                      client overview. The `a` element used instead of react-router `Link`
                      as workaround for caching issue when visiting client from case */}
                <a data-pii href={`/clients/${id}/overview`}>
                  {clientName}
                </a>
                {index !== clients.length - 1 && ', '}
              </span>
            )
          }
          return result
        })}
        {clients.length > CLIENTS_TO_SHOW && `+${clients.length - CLIENTS_TO_SHOW} others`}
      </NavContent>
    )
  }
  return null
}

export default ComplianceCaseDetailsNav
