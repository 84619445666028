import CasePropertyNav from './CasePropertyNav'
import CaseSourcingNav from './CaseSourcingNav'
import ClientPropertyNav from './ClientPropertyNav'
import ComplianceCaseDetailsNav from './ComplianceCaseDetailsNav'
import GeneralInsuranceCompareQuotesNav from './GeneralInsuranceCompareQuotesNav'
import { getPageType, MainNavProps, PageType } from './Nav.helpers'

const MainNavContent = ({ pathname }: MainNavProps) => {
  switch (getPageType(pathname)) {
    case PageType.CaseSourcing:
      return <CaseSourcingNav pathname={pathname} />

    case PageType.ClientProperty:
      return <ClientPropertyNav pathname={pathname} />

    case PageType.CaseProperty:
      return <CasePropertyNav pathname={pathname} />

    case PageType.GeneralInsuranceCompareQuotes:
      return <GeneralInsuranceCompareQuotesNav pathname={pathname} />
    case PageType.ComplianceCaseDetails:
      return <ComplianceCaseDetailsNav pathname={pathname} />
  }
  return null
}

export default MainNavContent
