import React, { memo } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'

import { useFormatMessage } from '@acre/utils'
import { useGetClientNameQuery } from '@acre/graphql'
import { IconTypes, UserAvatar } from '@acre/design-system'

import { NavContent, randomColourIdGenerator } from './Nav.helpers'

type Props = {
  clientId: string
}

const ClientName = ({ clientId }: Props) => {
  const theme = useTheme()
  const formatMessage = useFormatMessage()

  const { data, loading, error } = useGetClientNameQuery({
    variables: { id: clientId },
  })

  const first_name = data?.client.details.first_name
  const last_name = data?.client.details.last_name

  if (loading || error) {
    return <NavContent iconName={IconTypes.House} copyId="clients.properties.properties" />
  }

  if (first_name && last_name) {
    const colourId = randomColourIdGenerator()
    return (
      <Box display="flex" flexDirection="row" alignItems="center" data-pii>
        <UserAvatar initials={first_name.charAt(0).toUpperCase()} colourId={colourId} />
        <Box ml={theme.spacers.size8}>{`${first_name} ${last_name}`}</Box>
      </Box>
    )
  }
  return <div>{formatMessage('nav.company')}</div>
}

export default memo(ClientName)
