import React from 'react'
import { matchPath } from 'react-router-dom'

import { PREFERENCE_MAP } from '@acre/utils'
import { MortgageReason, useGetCaseQuery } from '@acre/graphql'
import { Icon, IconTypes } from '@acre/design-system'

import { MainNavProps, NavContent } from './Nav.helpers'
import { GreyLink } from './Nav.styles'

const CaseSourcingNav = ({ pathname }: MainNavProps) => {
  const match = matchPath(
    {
      path: '/sourcing/:id',
    },
    pathname,
  )
  const id = match!.params.id!

  const { data, loading, error } = useGetCaseQuery({
    variables: { id },
  })

  const reason = data?.case.details.preference_mortgage_reason || MortgageReason.InvalidMortgageReason

  if (loading || error) {
    return <NavContent iconName={IconTypes.Sourcing} copyId="sourcing.title" />
  }

  return (
    <>
      <GreyLink to={`/cases/${id}/overview`}>
        <NavContent iconName={IconTypes.Cases} copyId={PREFERENCE_MAP[reason]} />
      </GreyLink>
      <Icon name={IconTypes.ArrowRight} />
      <NavContent iconName={IconTypes.Sourcing} copyId="sourcing.title" />
    </>
  )
}

export default CaseSourcingNav
