import { RedirectOnRoot } from '@broker-crm-utils/routing.helpers'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import { lazyLoader, lazyRoute, multiRoutes, RequireAuth } from '@acre/utils'

import NotFound from '../pages/NotFound'
import Root from '../Root'
import RequireOnboarding from './RequireOnboarding'

const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <RedirectOnRoot />
          </RequireAuth>
        ),
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
      {
        path: 'login',
        lazy: lazyRoute(() => import('./lazy/Login')),
      },
      {
        path: 'welcome',
        lazy: lazyRoute(() => import('./lazy/Onboarding')),
        children: [
          {
            index: true,
            element: <RequireOnboarding userPath="user/home" organisationPath="company/home" />,
          },

          /* User */
          {
            path: 'user/*',
            lazy: lazyRoute(() => import('./lazy/OnboardingUserOnboarding')),
            children: [
              { path: 'home', lazy: lazyRoute(() => import('./lazy/OnboardingWelcomeScreen')) },
              { path: 'contact-details', lazy: lazyRoute(() => import('./lazy/OnboardingOrganisationUserDetails')) },
              { path: 'credentials', lazy: lazyRoute(() => import('./lazy/OnboardingUserCredentialsPage')) },
              { path: 'email-and-calendar', lazy: lazyRoute(() => import('./lazy/OnboardingEmailAndCalendar')) },
              { path: 'complete', lazy: lazyRoute(() => import('./lazy/OnboardingCompletionScreen')) },
            ],
          },

          /* Org */
          {
            path: 'company/*',
            lazy: lazyRoute(() => import('./lazy/OnboardingOrgOnboarding')),
            children: [
              { path: 'home', lazy: lazyRoute(() => import('./lazy/OnboardingWelcomeScreen')) },
              { path: 'details', lazy: lazyRoute(() => import('./lazy/OnboardingCompanyInformation')) },
              /* Org Panels */
              {
                path: 'panels',
                lazy: lazyRoute(() => import('./lazy/OnboardingOrgPanels')),
                children: [
                  { path: '1', lazy: lazyRoute(() => import('./lazy/OnboardingMortgagePanel')) },
                  { path: '2', lazy: lazyRoute(() => import('./lazy/OnboardingPanels')) },
                  { path: '*', element: <Navigate to="1" replace /> },
                ],
              },
              { path: 'documents', lazy: lazyRoute(() => import('./lazy/OnboardingTemplates')) },
              { path: 'fees', lazy: lazyRoute(() => import('./lazy/OnboardingMortgageFees')) },
              { path: 'users', lazy: lazyRoute(() => import('./lazy/OnboardingUsersContainer')) },
              { path: 'complete', lazy: lazyRoute(() => import('./lazy/OnboardingCompletionScreen')) },
              { path: '*', element: <Navigate to="home" replace /> },
            ],
          },
        ],
      },

      /* Dashboard */
      {
        path: 'dashboard/performance',
        lazy: lazyRoute(() => import('./lazy/Dashboard')),
        children: [
          {
            path: 'company',
            lazy: lazyRoute(() => import('./lazy/CompanyDashboard')),
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/CompanyDashboard')) },
              { path: ':id', lazy: lazyRoute(() => import('./lazy/CompanyDashboard')) },
            ],
          },
          {
            path: 'user',
            lazy: lazyRoute(() => import('./lazy/UserDashboard')),
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/UserDashboard')) },
              { path: ':id', lazy: lazyRoute(() => import('./lazy/UserDashboard')) },
            ],
          },
          {
            path: 'network',
            lazy: lazyRoute(() => import('./lazy/NetworkDashboard')),
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/NetworkDashboard')) },
              { path: ':id', lazy: lazyRoute(() => import('./lazy/NetworkDashboard')) },
            ],
          },
        ],
      },

      /* Compliance dashboard */
      {
        path: 'dashboard/compliance',
        lazy: lazyRoute(() => import('./lazy/Dashboard')),
        children: [
          {
            path: 'company',
            lazy: lazyRoute(() => import('./lazy/ComplianceCompanyDashboard')),
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/ComplianceCompanyDashboard')) },
              { path: ':id', lazy: lazyRoute(() => import('./lazy/ComplianceCompanyDashboard')) },
            ],
          },
          {
            path: 'user',
            lazy: lazyRoute(() => import('./lazy/ComplianceUserDashboard')),
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/ComplianceUserDashboard')) },
              { path: ':id', lazy: lazyRoute(() => import('./lazy/ComplianceUserDashboard')) },
            ],
          },
          {
            path: 'network',
            lazy: lazyRoute(() => import('./lazy/ComplianceNetworkDashboard')),
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/ComplianceNetworkDashboard')) },
              { path: ':id', lazy: lazyRoute(() => import('./lazy/ComplianceNetworkDashboard')) },
            ],
          },
        ],
      },

      /* Cases */
      ...multiRoutes({
        paths: ['cases/*', 'compliance/cases/*'],
        lazy: lazyRoute(() => import('./lazy/Cases')),
        children: [
          {
            path: 'list-view',
            lazy: lazyRoute(() => import('./lazy/CaseViews')),
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/CasesList')) },
              { path: ':customView', lazy: lazyRoute(() => import('./lazy/CasesList')) },
            ],
          },

          {
            path: 'compliance-view',
            lazy: lazyRoute(() => import('./lazy/CaseViews')),
            children: [
              { index: true, lazy: lazyRoute(() => import('./lazy/CaseComplianceList')) },
              { path: ':id', lazy: lazyRoute(() => import('./lazy/CaseComplianceCaseDetails')) },
            ],
          },

          {
            path: 'board-view',
            lazy: lazyRoute(() => import('./lazy/CaseViews')),
            children: [{ index: true, lazy: lazyRoute(() => import('./lazy/CasesBoard')) }],
          },

          {
            path: ':caseId/*',
            lazy: lazyRoute(() => import('./lazy/CaseDetails')),
            children: [
              { path: 'overview', lazy: lazyRoute(() => import('./lazy/CaseOverview')) },

              {
                path: 'properties/*',
                children: [
                  ...multiRoutes({
                    paths: ['new', 'new/:propertyType'],
                    lazy: lazyRoute(() => import('./lazy/FactFindNewProperty')),
                  }),

                  ...multiRoutes({
                    paths: ['edit', 'edit/:propertyId'],
                    lazy: lazyRoute(() => import('./lazy/FactFindEditProperty')),
                  }),
                ],
              },

              ...multiRoutes({
                paths: ['fact-find/*', 'company-fact-find/*'],
                lazy: () => import('./lazy/CaseFactFind'),
                children: [
                  {
                    path: ':clientId/*',
                    children: [
                      { path: 'personal-info', lazy: lazyRoute(() => import('./lazy/FactFindPersonalInfo')) },
                      { path: 'credit', lazy: lazyRoute(() => import('./lazy/FactFindCredit')) },
                      { path: 'income', lazy: lazyRoute(() => import('./lazy/FactFindIncome')) },
                      { path: 'id-verification', lazy: lazyRoute(() => import('./lazy/FactFindIdVerification')) },
                    ],
                  },

                  { path: 'employment', lazy: lazyRoute(() => import('./lazy/FactFindEmployment')) },

                  {
                    path: 'properties/*',
                    children: [
                      { index: true, lazy: lazyRoute(() => import('./lazy/FactFindProperties')) },

                      {
                        path: ':propertyId/property-info/*',
                        lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfo')),
                        children: [
                          { path: 'overview', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoOverview')) },
                          { path: 'title', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoTitle')) },
                          {
                            path: 'property-prices',
                            lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoPrices')),
                          },
                          { path: 'crime', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoCrime')) },
                          {
                            path: 'geological',
                            lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoGeological')),
                          },
                          {
                            path: 'listed-buildings',
                            lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoListedBuildings')),
                          },
                          { path: 'radon', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoRadon')) },
                          { path: 'mining', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoMining')) },
                          {
                            path: 'protected-areas',
                            lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoProtectedAreas')),
                          },
                          { path: 'polluters', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoPolluters')) },
                          { path: 'flooding', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoFlooding')) },
                          {
                            path: 'major-projects',
                            lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoMajorProjects')),
                          },
                          { path: 'schools', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoSchools')) },
                          {
                            path: 'communications',
                            lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoCommunications')),
                          },
                          { path: 'llc', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoLLC')) },
                          { path: 'epc', lazy: lazyRoute(() => import('./lazy/FactFindPropertyInfoEPC')) },
                        ],
                      },
                    ],
                  },

                  { path: 'mortgage', lazy: lazyRoute(() => import('./lazy/FactFindMortgage')) },

                  { path: 'conveyancing', lazy: lazyRoute(() => import('./lazy/FactFindConveyancing')) },

                  { path: 'outgoings', lazy: lazyRoute(() => import('./lazy/FactFindOutgoings')) },

                  { path: 'protection', lazy: lazyRoute(() => import('./lazy/FactFindProtectionReferral')) },
                ],
              }),

              {
                path: 'protection',
                children: [
                  { path: 'sourcing', lazy: lazyRoute(() => import('./lazy/CaseProtectionSourcing')) },
                  { path: 'products', lazy: lazyRoute(() => import('./lazy/CaseProtectionProducts')) },
                ],
              },

              { path: 'products', lazy: lazyRoute(() => import('./lazy/CaseProducts')) },

              {
                path: 'home-insurance',
                children: [
                  { index: true, lazy: lazyRoute(() => import('./lazy/CaseHomeInsurance')) },
                  {
                    path: 'assumptions/:quoteID/:premiumID',
                    lazy: lazyRoute(() => import('./lazy/CaseHomeInsuranceAssumptions')),
                  },
                ],
              },
              {
                path: 'home-insurance-products',
                children: [
                  { index: true, lazy: lazyRoute(() => import('./lazy/CaseHomeInsuranceProducts')) },
                  {
                    path: ':giProductId',
                    lazy: lazyRoute(() => import('./lazy/CaseHomeInsuranceProductDetails')),
                  },
                  {
                    path: 'new/:productType',
                    lazy: lazyRoute(() => import('./lazy/CaseHomeInsuranceProductNew')),
                  },
                ],
              },

              { path: 'documents', lazy: lazyRoute(() => import('./lazy/CaseDocuments')) },

              { path: 'contacts', lazy: lazyRoute(() => import('./lazy/CaseContacts')) },

              {
                path: 'details',
                lazy: lazyRoute(() => import('./lazy/CaseRequirements')),
                children: [
                  { path: 'preferences', lazy: lazyRoute(() => import('./lazy/CaseRequirementsPreferences')) },
                  { path: 'recommendation', lazy: lazyRoute(() => import('./lazy/CaseRequirementsRecommendation')) },
                  {
                    path: 'existing-protection',
                    lazy: lazyRoute(() => import('./lazy/CaseRequirementsExistingProtection')),
                  },
                  {
                    path: 'protection-requirements',
                    lazy: lazyRoute(() => import('./lazy/CaseRequirementsProtectionRequirements')),
                  },
                  {
                    path: 'protection-needs',
                    lazy: lazyRoute(() => import('./lazy/CaseRequirementsProtectionNeeds')),
                  },
                ],
              },

              { path: 'notes', lazy: lazyRoute(() => import('./lazy/CaseNotes')) },
              {
                path: 'suitability',
                lazy: lazyRoute(() => import('./lazy/CaseSuitability')),
                children: [
                  { path: 'mortgage', lazy: lazyRoute(() => import('./lazy/SuitabilitySuitabilityReport')) },
                  {
                    path: 'protection',
                    lazy: lazyRoute(() => import('./lazy/SuitabilityProtectionSuitabilityReport')),
                  },
                ],
              },
              { path: 'accounting', lazy: lazyRoute(() => import('./lazy/CaseLedger')) },
              { path: 'wills', lazy: lazyRoute(() => import('./lazy/CaseWills')) },

              {
                path: 'review-mode',
                children: [
                  {
                    path: 'version/:version/preferences',
                    lazy: lazyRoute(() => import('./lazy/ReviewModePreferences')),
                  },
                  {
                    path: 'recommendation',
                    lazy: lazyRoute(() => import('./lazy/ReviewModeRecommendation')),
                  },
                  {
                    path: 'version/:version/explanations',
                    lazy: lazyRoute(() => import('./lazy/ReviewModeExplanations')),
                  },
                  {
                    path: 'document-requirements',
                    lazy: lazyRoute(() => import('./lazy/ReviewModeDocumentRequirements')),
                  },
                  { path: 'version/:version/fact-find', lazy: lazyRoute(() => import('./lazy/ReviewModeFactFind')) },
                  {
                    path: 'id-verification',
                    lazy: lazyRoute(() => import('./lazy/ReviewModeIdVerifications')),
                  },
                  {
                    path: 'documents/:documentType/:documentId',
                    lazy: lazyRoute(() => import('./lazy/DocumentReview')),
                  },
                  {
                    path: 'documents/:documentType/:documentId/mortgage/:mortgageId',
                    lazy: lazyRoute(() => import('./lazy/DocumentReview')),
                  },
                  {
                    path: 'documents/:documentType/list',
                    lazy: lazyRoute(() => import('./lazy/ESISGrid')),
                    loader: lazyLoader(() => import('./lazy/ESISGridLoader')),
                  },
                  {
                    path: 'verifications',
                    lazy: lazyRoute(() => import('./lazy/ReviewModeVerifications')),
                    children: [
                      { path: 'complete', lazy: lazyRoute(() => import('./lazy/VerificationsComplete')) },
                      { path: 'outstanding', lazy: lazyRoute(() => import('./lazy/VerificationsOutstanding')) },
                    ],
                  },
                ],
              },
            ],
          },

          { path: 'new', lazy: lazyRoute(() => import('./lazy/CaseNew')) },

          { path: '*', element: <Navigate to="board-view" replace /> },
        ],
      }),

      /* Compliance */
      {
        path: 'compliance/cases',
        lazy: lazyRoute(() => import('./lazy/CaseCompliance')),
        children: [
          {
            path: '*',
            lazy: lazyRoute(() => import('./lazy/CaseViews')),
            children: [{ path: '*', element: <Navigate to="/cases/compliance-view" replace /> }],
          },
        ],
      },

      /* Go Cardless Callback */
      { path: '/go-cardless-callback', lazy: lazyRoute(() => import('./lazy/AuthCallback')) },

      /* Clients */
      {
        path: 'clients',
        lazy: lazyRoute(() => import('./lazy/Clients')),
        children: [
          { index: true, lazy: lazyRoute(() => import('./lazy/ClientsList')) },
          {
            path: ':clientId/*',
            lazy: lazyRoute(() => import('./lazy/ClientDetails')),
            children: [
              { path: 'overview', lazy: lazyRoute(() => import('./lazy/ClientOverview')) },
              {
                path: 'profile/*',
                lazy: lazyRoute(() => import('./lazy/ClientProfile')),
                children: [
                  { path: 'personal-info', lazy: lazyRoute(() => import('./lazy/ClientProfilePersonalInfo')) },
                  { path: 'id-verification', lazy: lazyRoute(() => import('./lazy/ClientProfileIdVerification')) },
                  { path: 'credit', lazy: lazyRoute(() => import('./lazy/ClientProfileCredit')) },
                  { path: 'properties', lazy: lazyRoute(() => import('./lazy/ClientProfileProperties')) },
                  { path: 'income', lazy: lazyRoute(() => import('./lazy/ClientProfileIncome')) },
                  { path: 'outgoings', lazy: lazyRoute(() => import('./lazy/ClientProfileOutgoings')) },
                  { path: 'company-info', lazy: lazyRoute(() => import('./lazy/ClientProfileCompanyInfo')) },
                  { path: 'conveyancing', lazy: lazyRoute(() => import('./lazy/ClientProfileConveyancing')) },
                ],
              },

              /* Clients properties*/
              {
                path: 'properties',
                children: [
                  { path: 'new', lazy: lazyRoute(() => import('./lazy/ClientProfileNewProperty')) },
                  { path: 'edit/:propertyId', lazy: lazyRoute(() => import('./lazy/ClientProfileEditProperty')) },
                  {
                    path: ':propertyId/property-info',
                    lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfo')),
                    children: [
                      { path: 'overview', lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoOverview')) },
                      { path: 'title', lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoTitle')) },
                      {
                        path: 'property-prices',
                        lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoPrices')),
                      },
                      { path: 'crime', lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoCrime')) },
                      {
                        path: 'geological',
                        lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoGeological')),
                      },
                      {
                        path: 'listed-buildings',
                        lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoListedBuildings')),
                      },
                      { path: 'radon', lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoRadon')) },
                      { path: 'mining', lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoMining')) },
                      {
                        path: 'protected-areas',
                        lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoProtectedAreas')),
                      },
                      { path: 'polluters', lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoPolluters')) },
                      { path: 'flooding', lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoFlooding')) },
                      {
                        path: 'major-projects',
                        lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoMajorInfra')),
                      },
                      { path: 'schools', lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoSchools')) },
                      {
                        path: 'communications',
                        lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoCommunications')),
                      },
                      {
                        path: 'llc',
                        lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoLocalLandCharges')),
                      },
                      { path: 'epc', lazy: lazyRoute(() => import('./lazy/ClientProfilePropertyInfoEPC')) },
                    ],
                  },
                ],
              },
              {
                path: 'health',
                lazy: lazyRoute(() => import('./lazy/ClientHealth')),
                children: [{ path: 'nicotine', lazy: lazyRoute(() => import('./lazy/ClientHealthNicotine')) }],
              },
              { path: 'cases', lazy: lazyRoute(() => import('./lazy/ClientCases')) },
              { path: 'documents', lazy: lazyRoute(() => import('./lazy/ClientDocuments')) },
              { path: 'protection', lazy: lazyRoute(() => import('./lazy/ClientProtection')) },
              { path: 'communication', lazy: lazyRoute(() => import('./lazy/ClientCommunication')) },
              { path: 'settings', lazy: lazyRoute(() => import('./lazy/ClientSettings')) },
            ],
          },
        ],
      },

      /* Reminders */
      { path: 'reminders', lazy: lazyRoute(() => import('./lazy/MyRemindersList')) },

      /* Reports */
      {
        path: 'reports/:caseId',
        lazy: lazyRoute(() => import('./lazy/Reports')),
        children: [
          { path: 'currentPipeline', lazy: lazyRoute(() => import('./lazy/ReportsCurrentPipeline')) },
          { path: 'cancellationReason', lazy: lazyRoute(() => import('./lazy/ReportsCancellationReason')) },
          { path: 'caseStatusWaterfall', lazy: lazyRoute(() => import('./lazy/ReportsCaseWaterfall')) },
          { path: 'revenue', lazy: lazyRoute(() => import('./lazy/ReportsRevenue')) },
          { path: 'commissions', lazy: lazyRoute(() => import('./lazy/ReportsCommissions')) },
          { path: 'other', lazy: lazyRoute(() => import('./lazy/ReportsOtherReports')) },
          { path: 'rmar', lazy: lazyRoute(() => import('./lazy/ReportsRMARReports')) },
          { path: 'global/csv', lazy: lazyRoute(() => import('./lazy/ReportsGlobalCSV')) },
        ],
      },

      /* Accounting */
      {
        path: 'accounting',
        lazy: lazyRoute(() => import('./lazy/Accounting')),
        children: [
          { path: 'payments-due', lazy: lazyRoute(() => import('./lazy/AccountingPaymentsDueContainer')) },
          { path: 'payments-settled', lazy: lazyRoute(() => import('./lazy/AccountingPaymentsReceivedContainer')) },
          { path: 'outbound-payments', lazy: lazyRoute(() => import('./lazy/AccountingOutboundPaymentsContainer')) },
          { path: 'outbound-settled', lazy: lazyRoute(() => import('./lazy/AccountingOutboundSettledContainer')) },
        ],
      },

      /* User Settings */
      {
        path: 'user',
        lazy: lazyRoute(() => import('./lazy/User')),
        children: [
          {
            path: ':id',
            children: [
              { path: 'settings/credentials', lazy: lazyRoute(() => import('./lazy/UserUserCredentialsPage')) },
              { path: 'settings/email', lazy: lazyRoute(() => import('./lazy/UserEmailAndCalendar')) },
              { path: '*', element: <Navigate replace to="settings/credentials" /> },
            ],
          },
        ],
      },

      /* Org Settings */
      {
        path: 'company',
        lazy: lazyRoute(() => import('./lazy/CompanyLayout')),
        children: [
          {
            path: ':orgId',
            lazy: lazyRoute(() => import('./lazy/Company')),
            loader: lazyLoader(() => import('./lazy/CompanyLoader')),
            children: [
              { path: 'information', lazy: lazyRoute(() => import('./lazy/CompanyCompanyInformation')) },
              { path: 'appointed-representatives', lazy: lazyRoute(() => import('./lazy/CompanyFirmList')) },
              { path: 'mortgage-fees', lazy: lazyRoute(() => import('./lazy/CompanyMortgageFees')) },
              {
                path: 'users',
                children: [
                  { index: true, lazy: lazyRoute(() => import('./lazy/CompanyUsers')) },
                  {
                    path: ':contactId',
                    children: [
                      { path: 'user-details', lazy: lazyRoute(() => import('./lazy/CompanyOrganisationUserDetails')) },
                      { path: 'competencies', lazy: lazyRoute(() => import('./lazy/CompanyUserCompetencies')) },
                      {
                        path: 'advanced-permissions',
                        lazy: lazyRoute(() => import('./lazy/CompanyAdvancedPermissions')),
                      },
                      { path: 'commission', lazy: lazyRoute(() => import('./lazy/CompanyComissionManagement')) },
                      { path: 'feature-flags', lazy: lazyRoute(() => import('./lazy/CompanyFeatureFlags')) },
                    ],
                  },
                ],
              },
              {
                path: 'process',
                lazy: lazyRoute(() => import('./lazy/CompanyProcess')),
                children: [
                  { path: 'reminders', lazy: lazyRoute(() => import('./lazy/CompanyProcessReminders')) },
                  { path: 'calendar', lazy: lazyRoute(() => import('./lazy/CompanyProcessCalendar')) },
                ],
              },
              {
                path: 'introducers',
                lazy: lazyRoute(() => import('./lazy/Introducers')),
                children: [
                  { index: true, lazy: lazyRoute(() => import('./lazy/IntroducersIntroducersList')) },
                  {
                    path: ':id',
                    lazy: lazyRoute(() => import('./lazy/IntroducersIntroducerDetails')),
                    children: [
                      {
                        path: 'company-information',
                        lazy: lazyRoute(() => import('./lazy/IntroducersCompanyInformation')),
                      },
                      { path: 'access-control', lazy: lazyRoute(() => import('./lazy/IntroducersAccessControl')) },
                      {
                        path: 'contacts',
                        children: [
                          { index: true, lazy: lazyRoute(() => import('./lazy/IntroducersOrganisationUsers')) },
                          {
                            path: ':contactId',
                            children: [
                              {
                                path: 'contact-details',
                                lazy: lazyRoute(() => import('./lazy/IntroducersOrganisationUserDetails')),
                              },
                              {
                                path: 'commission',
                                lazy: lazyRoute(() => import('./lazy/IntroducersCommissionManagement')),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              { path: 'mortgage-panel', lazy: lazyRoute(() => import('./lazy/CompanyMortgagePanel')) },
              { path: 'gi-panel', lazy: lazyRoute(() => import('./lazy/CompanyPanels')) },
              { path: 'templates', lazy: lazyRoute(() => import('./lazy/CompanyTemplates')) },
              { path: 'integrations', lazy: lazyRoute(() => import('./lazy/CompanyIntegrations')) },
              { path: 'regulatory', lazy: lazyRoute(() => import('./lazy/CompanyRegulatoryInformation')) },
              { path: 'document-requirements', lazy: lazyRoute(() => import('./lazy/CompanyDocumentRequirements')) },
              { path: 'payments', lazy: lazyRoute(() => import('./lazy/CompanyPayments')) },
              { path: 'commercial-details', lazy: lazyRoute(() => import('./lazy/CompanyCommercialDetails')) },
              { path: 'user-groups', lazy: lazyRoute(() => import('./lazy/CompanyUserGroups')) },
              { path: 'firms', lazy: lazyRoute(() => import('./lazy/GatedCompanyFirmList')) },
              { path: 'feature-flags', lazy: lazyRoute(() => import('./lazy/CompanyFeatureFlags')) },
              { path: 'credit-support-tools', lazy: lazyRoute(() => import('./lazy/CompanyCreditSupportTools')) },
              { path: 'calendar-structures', lazy: lazyRoute(() => import('./lazy/CompanyCalendarStructures')) },
            ],
          },
        ],
      },

      { path: 'refresh', lazy: lazyRoute(() => import('./lazy/Refresh')) },

      ...multiRoutes({
        paths: ['documents/:id', 'documents/:id/cases/:caseId'],
        lazy: () => import('./lazy/Documents'),
      }),
    ],
  },
])

const Router = () => {
  return <RouterProvider router={browserRouter} future={{ v7_startTransition: true }} />
}

export default Router
