import React from 'react'
import { matchPath } from 'react-router-dom'

import { Icon, IconTypes } from '@acre/design-system'

import ClientName from './ClientName'
import { NavContent } from './Nav.helpers'
import { GreyLink } from './Nav.styles'

type Props = {
  pathname: string
}

const ClientPropertyNav = ({ pathname }: Props) => {
  const match = matchPath(
    {
      path: '/properties/:propertyId/client/:clientId',
    },
    pathname,
  )
  const clientId = match!.params.clientId!

  return (
    <>
      <GreyLink to={`/clients/${clientId}/overview`}>
        <NavContent>
          <ClientName clientId={clientId} />
        </NavContent>
      </GreyLink>
      <Icon name={IconTypes.ArrowRight} />
      <NavContent iconName={IconTypes.Home} copyId="clients.properties.properties" />
    </>
  )
}

export default ClientPropertyNav
