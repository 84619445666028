import React, { useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { Box } from '@mui/material'
import { Form } from 'react-final-form'
import { FormattedMessage } from 'react-intl'

import { useRouteForCase } from '@acre/utils'
import { ErrorProvider, isBusinessProtection, unwrapErrors, useFormatMessage } from '@acre/utils'
import { ProtectionProduct, UpdateProtectionProductDocument, UpdateProtectionProductMutation } from '@acre/graphql'
import { BUTTON_PRIMARY, ColouredItemCard, H2, Modal, ModalFooter } from '@acre/design-system'

import ProtectionCommissionFields from '../../../../../../Protection/ProtectionCommissionFields/ProtectionCommissionFields'
import {
  convertProtectionProductValuesForApi,
  getProtectionProductInitialValues,
  protectionCommissionSchema,
  protectionProductDecorator,
} from '../../../../../../Protection/ProtectionCommissionFields/ProtectionCommissionFields.helpers'
import {
  getIndividualBenefitFields,
  messagePrefix,
  protectionCommissionCompulsoryFieldsSchema,
} from './ProtectionDetailsModal.helpers'

const validate = unwrapErrors(protectionCommissionSchema.concat(protectionCommissionCompulsoryFieldsSchema))
type Props = {
  onSubmit: () => void
  isModalOpen: boolean
  onClose: () => void
  product: ProtectionProduct
  stage: string
}

const ProtectionDetailsModal = ({ onSubmit, isModalOpen, onClose, product, stage }: Props) => {
  const productDetails = product.details

  const { data } = useRouteForCase()

  const isBusinessProtectionCase = isBusinessProtection(data?.details?.preference_mortgage_reason)

  const formatMessage = useFormatMessage()

  const productDetailsFields = useMemo(
    () => [
      {
        id: 'ProductProviderName',
        label: <FormattedMessage id={`${messagePrefix}.provider`} />,
        value: productDetails.provider_name,
      },
      {
        id: 'ProductName',
        label: <FormattedMessage id={`${messagePrefix}.product`} />,
        value: productDetails.product_name,
      },
      ...getIndividualBenefitFields({ benefits: productDetails.product_benefits, formatMessage }),
    ],
    [formatMessage, productDetails.product_benefits, productDetails.product_name, productDetails.provider_name],
  )

  const initialValues = getProtectionProductInitialValues(productDetails, isBusinessProtectionCase)

  const [updateProtectionProduct, { loading }] = useMutation<UpdateProtectionProductMutation>(
    UpdateProtectionProductDocument,
    {
      onError: (err) => {
        ErrorProvider.showError(new Error(err.message))
      },
    },
  )

  const handleSubmit = useCallback(
    async (productInput: typeof initialValues) => {
      const input = convertProtectionProductValuesForApi(productInput)
      // need to get only changedValues, as sending null value for some fields
      // like renewal_commission_start_date is not allowed by the BE

      const payload = {
        variables: {
          input,
          protection_id: product.protection_id,
        },
      }
      await updateProtectionProduct(payload)
      onSubmit()
    },
    [onSubmit, product.protection_id, updateProtectionProduct],
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      decorators={[protectionProductDecorator]}
      validate={validate}
    >
      {({ handleSubmit, pristine, hasValidationErrors }) => (
        <Modal
          className="modal-reduced-padding"
          id="ConfirmCaseTransitionModal"
          open={isModalOpen}
          onClose={onClose}
          header={{
            left: (
              <H2 styledAs="h5">
                <FormattedMessage id={`${messagePrefix}.confirmProtectionDetails`} values={{ stage }} />
              </H2>
            ),
          }}
          footer={
            <Footer
              isLoading={loading}
              onSubmit={pristine ? onSubmit : handleSubmit}
              onClose={onClose}
              isDisabled={hasValidationErrors}
            />
          }
        >
          <Box mb={2}>
            <ColouredItemCard
              title={formatMessage(`${messagePrefix}.protectionProductDetails`)}
              id="MortgageDetailsCard"
              data={productDetailsFields}
            />
          </Box>
          <ProtectionCommissionFields
            premiumFrequency={productDetails?.premium_frequency}
            variant="compactLeftAligned"
            fieldLayout="horizontal"
          />
        </Modal>
      )}
    </Form>
  )
}

export default ProtectionDetailsModal

const Footer = ({
  isLoading,
  onSubmit,
  onClose,
  isDisabled,
}: {
  isLoading: boolean
  onSubmit: () => void
  onClose: () => void
  isDisabled: boolean
}) => {
  return (
    <ModalFooter
      secondaryButton={{ onClick: () => onClose(), id: 'CancelConfirmProtectionProductDetails', isDisabled: false }}
      primaryButton={{
        id: 'ConfirmProtectionProductDetails',
        style: BUTTON_PRIMARY,
        onClick: () => onSubmit(),
        isLoading: isLoading,
        text: `caseTransitions.toComplete.next`,
        isDisabled,
      }}
    />
  )
}
