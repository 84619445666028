import React, { FocusEvent, ReactNode, useCallback } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'
import classNames from 'classnames'

import { useFormatMessage } from '@acre/utils'
import { convertPoundsToPence, Maybe } from '@acre/graphql'

import withDisabled from '../../hoc/withDisabled'
import useFieldDisabledState from '../../hooks/useFieldDisabledState'
import { HELPER_TYPE_ERROR, Variant } from '../../utils/constants'
import testHandle from '../../utils/testHandle'
import { Size } from '../../utils/types'
import Icon, { IconName } from '../FeatherIcon'
import HelperText from '../HelperText'
import Label from '../Label'
import MatomoWrapper from '../MatomoWrapper'
import Tooltip from '../Tooltip'
import { NumberFormatProps } from './NumberFormat'
import { FormControlWrapper, LabelAndInputWrapper, StyledInputAddonLeft } from '../../styles/form-control.styles'
import { StyledInput } from './CurrencyInput.styles'

export type CurrencyInputProps = {
  value?: number | string | null
  onChange: (value: string) => void
  onBlur?: (e?: FocusEvent<HTMLInputElement>) => void
  onFocus?: (e?: FocusEvent<HTMLInputElement>) => void
  id?: string
  label?: string | JSX.Element
  error?: boolean
  message?: ReactNode
  name?: string
  disabled?: boolean
  ariaLabel?: string
  placeholder?: string
  conversion?: boolean
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  variant?: Variant
  extra?: Maybe<ReactNode>
  tooltip?: string
  secondaryText?: ReactNode
  labelWidth?: Maybe<string>
}

const CurrencyInput = ({
  id,
  label,
  value,
  error,
  message,
  name,
  disabled: disabledProp,
  ariaLabel,
  placeholder,
  tooltip,
  onChange,
  onBlur,
  onFocus,
  extra,
  conversion = true,
  isMissing = false,
  isIncomplete = false,
  verificationError,
  variant = 'default',
  secondaryText,
  labelWidth,
}: CurrencyInputProps) => {
  const formatMessage = useFormatMessage()
  const theme = useTheme()

  const disabled = useFieldDisabledState(disabledProp)
  const className = classNames({ error })
  const testId = id ? testHandle(id) : null
  const val = value ?? ''
  const numericVal = typeof val === 'string' ? parseFloat(val) : val

  const convertedNumVal = conversion ? numericVal / 100 : numericVal
  const initialValue = isNaN(numericVal) ? '' : convertedNumVal

  const handleChange = useCallback<NumberFormatProps['onChange']>(
    (event) => {
      const value = event.target.value.replace(/[,£]/g, '') || ''
      onChange(conversion ? convertPoundsToPence(value) || '' : value)
    },
    [conversion, onChange],
  )

  return (
    <MatomoWrapper onChange={handleChange} id={id} eventType={'currencyInputChange'} trackEventTrigger="onBlur">
      <FormControlWrapper data-testid={`${testId}Wrapper`} className={className}>
        <LabelAndInputWrapper variant={variant} labelWidth={labelWidth}>
          {label && (
            <Label
              htmlFor={id}
              isDisabled={false}
              text={label}
              isMissing={isMissing}
              isIncomplete={isIncomplete}
              verificationError={verificationError}
              secondaryText={secondaryText}
              variant={variant}
            />
          )}
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" width="100%">
              <Box display="flex" justifyContent="flex-end" width="100%">
                <StyledInput
                  id={id}
                  name={name || id}
                  disabled={disabled}
                  type="text"
                  placeholder={placeholder}
                  value={initialValue}
                  onChange={handleChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  aria-label={ariaLabel}
                  data-testid={testId}
                  autoComplete="off"
                  className={className}
                  variant={variant}
                  valueIsNumericString={typeof value === 'string'}
                />

                <StyledInputAddonLeft htmlFor={id} variant={variant}>
                  &pound;
                </StyledInputAddonLeft>
              </Box>

              {tooltip && (
                <Box ml={theme.spacers.size8}>
                  <Tooltip placement="right" content={tooltip}>
                    <Icon name={IconName.HelpCircle} size={Size.Small} />
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>
        </LabelAndInputWrapper>

        {message && (
          <HelperText
            id={`${id}Helper`}
            message={message || ''}
            textType={error ? HELPER_TYPE_ERROR : ''}
            variant={variant}
          />
        )}

        {isMissing && (
          <HelperText
            id={`${id}Helper`}
            message={formatMessage('errors.missingFieldRequired')}
            textType={HELPER_TYPE_ERROR}
            variant={variant}
          />
        )}
        {extra}
      </FormControlWrapper>
    </MatomoWrapper>
  )
}

export default withDisabled(CurrencyInput)
