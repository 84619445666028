import { SMALL } from '@broker-crm-utils'
import { HeaderSize } from '@broker-crm-utils'
import { styled, Theme } from '@mui/material/styles'
import { transparentize } from 'polished'
import { Link } from 'react-router-dom'

export const TitleButtonHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ResponsiveTitleButtonHeader = styled(TitleButtonHeader)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'flex-start',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

export const TextHeader = styled('header')`
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};

  h1 {
    margin-bottom: 0;
  }
`

export const BoldenText = styled('div')`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

// @TODO FRON-457 Dry up styles used here (Check ButtonStyles component)
export const LinkAsButton = styled(Link)`
  transition:
    opacity 0.1s ease-out,
    border-color 0.1s ease-out,
    background-color 0.1s ease-out;
  border-radius: ${({ theme }) => theme.display.borderRadius};
  padding-right: ${({ theme }) => theme.spacers.size32};
  padding-left: ${({ theme }) => theme.spacers.size32};
  height: ${({ theme }) => theme.display.inputHeight};

  font-size: 16px;

  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colours.primary};

  white-space: nowrap;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;

  &.fullWidth {
    width: 100%;
  }

  color: white;
  background-color: ${({ theme }) => theme.colours.primary};

  & svg path {
    color: white;
    stroke: white;
    fill: white;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colours.hover};
    background-color: ${({ theme }) => theme.colours.hover};
    text-decoration: none;
  }

  &:active {
    border-color: ${({ theme }) => theme.colours.pressed};
    background-color: ${({ theme }) => theme.colours.pressed};
  }

  &[aria-disabled] {
    border-color: white;
    background-color: ${({ theme }) => transparentize(theme.opacity.op80, theme.colours.primary)};
    pointer-events: none;
  }
`

export const UnsetMarginWrapper = styled('div')<{
  margin: keyof Theme['spacers']
}>`
  margin-left: -${({ margin, theme }) => theme.spacers[margin]};
  margin-right: -${({ margin, theme }) => theme.spacers[margin]};
`

export const sharedHrStyles = ({ theme }: { theme: Theme }) => ({
  content: "''",
  borderBottom: `1px solid ${theme.colours.baseLight}`,
  left: 0,
  position: 'absolute',
  marginLeft: theme.spacers.size32,
  width: `calc(100% - ${theme.spacers.size64})`,
})

export const CardRowLine = styled('div')<{ size?: HeaderSize }>(({ theme, size }) => ({
  position: 'relative',
  marginLeft: `-${theme.spacers.size32}`,
  marginRight: `-${theme.spacers.size32}`,

  paddingTop: size === SMALL ? theme.spacers.size8 : theme.spacers.size16,
  paddingBottom: size === SMALL ? theme.spacers.size8 : theme.spacers.size16,
  paddingLeft: theme.spacers.size32,
  paddingRight: theme.spacers.size32,
  lineHeight: 1.6,
  fontSize: theme.typography.pxToRem(size === SMALL ? 14 : 16),

  transition: 'all 0.1s ease-out',

  '&:hover': {
    cursor: 'pointer',
    color: theme.colours.primary,
    backgroundColor: transparentize(theme.opacity.op95, theme.colours.primary),
  },

  '&:active': {
    color: theme.colours.primary,
    backgroundColor: transparentize(theme.opacity.op80, theme.colours.primary),
  },

  '&::after': {
    ...sharedHrStyles({ theme }),
    bottom: 0,
  },

  '&:last-of-type': {
    '&::after': {
      ...sharedHrStyles({ theme }),
      bottom: 0,
    },
  },
}))

export const CardRow = styled(CardRowLine)<{ size?: HeaderSize }>``

/**
 *  TO BE DEPRECATED: import Masonry styles from common component styles
 */
export const MasonryLayout = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const MasonryColumn = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%; /* Make this dynamic */
  & + div {
    border-left: 1px solid ${({ theme }) => theme.colours.baseLight};
  }
`

export const MasonryCell = styled('div')`
  padding-top: ${({ theme }) => theme.spacers.size24};
  padding-left: ${({ theme }) => theme.spacers.size32};
  padding-right: ${({ theme }) => theme.spacers.size32};
  padding-bottom: ${({ theme }) => theme.spacers.size16};
  & + div {
    border-top: 1px solid ${({ theme }) => theme.colours.baseLight};
  }
  &:last-of-type {
    height: 100%;
  }
`
export const FullWidthMasonryCell = styled('div')`
  padding-top: ${({ theme }) => theme.spacers.size24};
  padding-bottom: ${({ theme }) => theme.spacers.size16};
  & + div {
    border-top: 1px solid ${({ theme }) => theme.colours.baseLight};
  }
  &:last-of-type {
    height: 100%;
  }
`
