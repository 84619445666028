import { useCallback, useState } from 'react'
import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'
import { reduceClientCases } from '@broker-crm-common/SearchComponents/SearchComponents'
import { useNotifications, userType } from '@broker-crm-utils'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Box, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

import { useUserContext } from '@acre/utils'
import { Feature, Flag, FlagType, getUserOrganisationIds, useFlag, useFormatMessage, useOpen } from '@acre/utils'
import {
  CaseStatus,
  GateName,
  PermissionScopeType,
  SearchResultItem,
  useGetCurrentUserQuery,
  useGetNotificationsQuery,
} from '@acre/graphql'
import {
  AcreSquareLogo,
  ExternalLink,
  FullPageLoadingSpinner,
  GlobalSearch,
  Nav,
  OptionsNavLink,
} from '@acre/design-system'

import { PermissionInput, usePermissionResolution } from '../../common/Permission'
import useSourcingUrl from '../../utils/hooks/useSourcingUrl'
import { NotificationButton } from '../Notifications/Notifications'
import { CaseOption, ClientOption } from '../SearchComponents'
import DashboardsNavPopover from './DashboardsNavPopover/DashboardsNavPopover'
import { getCaseSearchResultsAsync, getClientSearchResultsAsync, isPageWithoutNav } from './Nav.helpers'
import NavContent from './NavContent'
import PreviewModeButton from './PreviewModeButton'
import UserSettings from './UserSettings/UserSettings'

const MainNav = () => {
  const { pathname } = useLocation()
  const formatMessage = useFormatMessage()
  const navigate = useNavigate()

  const { close: closeModal } = useOpen(false)

  const apolloClient = useApolloClient()
  const user = useUserContext()
  const theme = useTheme()
  const hideClientList = useFlag([FlagType.Feature, Feature.HideClientList], false)
  const { trackSiteSearch } = useMatomo()
  const [displayDropDownMenu, setDisplayDropDownMenu] = useState(true)
  const client = useApolloClient()

  // Start polling for notifications from socket
  const { clearNotificationsById, clearAllNotifcations } = useNotifications(
    client as ApolloClient<NormalizedCacheObject>,
  )

  const { data: notifications } = useGetNotificationsQuery({
    variables: { userId: String(user?.id) },
    skip: !user?.id,
  })

  const { data, loading } = useGetCurrentUserQuery()

  const sourcingUrl = useSourcingUrl()

  const onGlobalSearchClick = useCallback(
    (value?: SearchResultItem) => {
      if (!value) return

      const [activeCases] = reduceClientCases(value)

      if (activeCases === 1) {
        const activeCaseID = value?.Cases?.filter(
          (caseObj) =>
            ![CaseStatus.Complete, CaseStatus.NotProceeding].includes(caseObj!.case_status || CaseStatus.InvalidStatus),
        )[0]?.id.replace(/.*_/, '')
        navigate && navigate(`/cases/${activeCaseID}/overview`)
        closeModal()
        return
      }

      if (value?.id) {
        navigate && navigate(`/clients/${value.id}/overview`)
        closeModal()
      }
    },
    [closeModal, navigate],
  )

  const onGlobalSearchCaseClick = useCallback(
    (value: any) => {
      if (value?.case?.id) {
        navigate && navigate(`/compliance/cases/${value.case.id}/overview`)
        closeModal()
      }
    },
    [closeModal, navigate],
  )

  const {
    VIEW_BROKER_DASHBOARD,
    VIEW_CASE_SECTION,
    VIEW_CASE_COMPLIANCE,
    VIEW_CLIENT_SECTION,
    VIEW_MI_DASHBOARD,
    ACCOUNTING,
    EDIT_CLIENT_RESTRICTED,
  } = GateName

  const permissionInputs: PermissionInput[] = [
    {
      gates: [VIEW_BROKER_DASHBOARD, VIEW_CLIENT_SECTION, ACCOUNTING, EDIT_CLIENT_RESTRICTED],
      entityIdsCollection: {
        [PermissionScopeType.organisation]: user ? [getUserOrganisationIds({ user }).userOrganisationId] : [],
      },
    },
    {
      gates: [VIEW_CASE_SECTION],
      entityIdsCollection: {
        [PermissionScopeType.organisation]: user ? [getUserOrganisationIds({ user }).userOrganisationId] : [],
        [PermissionScopeType.user]: user ? [user.id] : [],
      },
    },
    {
      gates: [VIEW_BROKER_DASHBOARD, VIEW_CLIENT_SECTION, ACCOUNTING, EDIT_CLIENT_RESTRICTED],
      entityIdsCollection: {
        [PermissionScopeType.organisation]: user ? [getUserOrganisationIds({ user }).userOrganisationId] : [],
      },
    },
    {
      gates: [VIEW_CASE_COMPLIANCE, VIEW_MI_DASHBOARD],
      entityIdsCollection: {
        [PermissionScopeType.organisation]: user ? getUserOrganisationIds({ user }).all : [],
      },
    },
  ]

  const { gates } = usePermissionResolution({
    inputs: permissionInputs,
  })

  const conditionalGlobalSearchProps =
    gates.includes(GateName.VIEW_CASE_COMPLIANCE) && !gates.includes(GateName.VIEW_CASE_SECTION)
      ? {
          executeSearch: getCaseSearchResultsAsync(apolloClient),
          onClick: onGlobalSearchCaseClick,
          optionComponent: CaseOption,
          placeholder: formatMessage('nav.search.enterCaseId'),
        }
      : {}

  if (isPageWithoutNav(pathname)) {
    return null
  }

  const reminderRoles = [
    userType.principal,
    userType.administrator,
    userType.advisor,
    userType.firmManager,
    userType.modifySupport,
    userType.readOnlySupport,
  ]

  const displayComplianceLink =
    gates.includes(GateName.VIEW_CASE_COMPLIANCE) && !gates.includes(GateName.VIEW_CASE_SECTION)

  return (
    <>
      {loading && <FullPageLoadingSpinner />}
      {data ? (
        ///TODO Add menu items on the Options Nav link using the useHoverListener hook
        //This will be done in a later ticket

        <Nav
          offset={theme.display.navHeightSmall.toString()}
          left={[
            <Box key="logo" display="flex" alignItems="center" ml={4} mr={12 / 8}>
              <AcreSquareLogo />
            </Box>,
            <DashboardsNavPopover gates={gates} key="DashboardsNavPopover" />,
            gates.includes(GateName.VIEW_CASE_SECTION) && (
              <OptionsNavLink
                to="/cases/board-view"
                id="MainNavCases"
                key="MainNavCases"
                isActive={(location) => location.startsWith('/cases')}
                alwaysRedirect
              >
                <FormattedMessage id="nav.cases" />
              </OptionsNavLink>
            ),
            displayComplianceLink && (
              <OptionsNavLink
                to="/cases/compliance-view"
                id="ComplianceCasesLink"
                key="ComplianceCasesLink"
                isActive={(location) => location.endsWith('/cases/compliance-view')}
                alwaysRedirect
              >
                <FormattedMessage id="nav.casesCompliance" />
              </OptionsNavLink>
            ),
            gates.includes(GateName.VIEW_CLIENT_SECTION) &&
              !(hideClientList && user?.primary_role_id == userType.advisor) && (
                <OptionsNavLink
                  to="/clients"
                  id="MainNavClientsLink"
                  key="MainNavClientsLink"
                  isActive={(location) => location.startsWith('/clients')}
                  alwaysRedirect
                >
                  <FormattedMessage id="nav.clients" />
                </OptionsNavLink>
              ),
            user?.primary_role_id && reminderRoles.includes(user.primary_role_id) && (
              <OptionsNavLink
                to="/reminders"
                id="RemindersLink"
                key="RemindersLink"
                isActive={(location) => location.startsWith('/reminders')}
                alwaysRedirect
              >
                <FormattedMessage id="nav.reminders" />
              </OptionsNavLink>
            ),

            <Flag keyPath={[FlagType.Feature, Feature.MI]} key="ReportsFlag" defaultValue={true}>
              <OptionsNavLink
                to={`/reports/${user?.organisation_id}`}
                id="ReportsLink"
                isActive={(location) => location.startsWith('/reports')}
                alwaysRedirect
              >
                <FormattedMessage id="nav.reports" />
              </OptionsNavLink>
            </Flag>,

            // Allow for unique keys in each array element
            ...(user?.primary_role_id && reminderRoles.includes(user.primary_role_id)
              ? [
                  <ExternalLink href={sourcingUrl} key={sourcingUrl}>
                    <FormattedMessage id="nav.sourcing.title" />
                  </ExternalLink>,
                ]
              : []),
            gates.includes(ACCOUNTING) && (
              <OptionsNavLink
                to={`/accounting/payments-due`}
                id="MainNavAccountingLink"
                key="MainNavAccountingLink"
                isActive={(location) => location.startsWith('/accounting')}
                alwaysRedirect
              >
                <FormattedMessage id="nav.accounting" />
              </OptionsNavLink>
            ),
          ]}
          right={
            <Box display="flex" alignItems="center" mr={3} ml={2} gap={2}>
              <GlobalSearch
                key="NavGlobalSearch"
                id="NavGlobalSearch"
                // @ts-ignore
                executeSearch={getClientSearchResultsAsync(
                  navigate,
                  apolloClient,
                  trackSiteSearch,
                  setDisplayDropDownMenu,
                )}
                menuIsOpen={!displayDropDownMenu ? false : undefined}
                optionComponent={ClientOption}
                onClick={onGlobalSearchClick}
                placeholder={formatMessage('nav.search.search')}
                onCloseModal={closeModal}
                {...conditionalGlobalSearchProps}
              />
              <PreviewModeButton />
              <NotificationButton
                notifications={notifications?.notifications || []}
                onClearAll={clearAllNotifcations}
                onClearById={clearNotificationsById}
                key="NavGlobalNotifications"
              />
              <UserSettings
                firstName={user?.first_name}
                lastName={user?.last_name}
                organisationId={user?.organisation_id}
                userId={user?.id}
              />
            </Box>
          }
        >
          <NavContent pathname={pathname} />
        </Nav>
      ) : null}
    </>
  )
}

export default MainNav
