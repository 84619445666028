import { ReactNode } from 'react'
import { Layout } from '@broker-crm-utils'
import { MissingFieldsSections } from '@broker-crm-utils'
import { GridSortItem } from '@mui/x-data-grid-pro'
import { AnySchema } from 'yup'

import { Checkbox, DropdownOption, IconName, OptionGroup, Variant } from '@acre/design-system'

// Types
type FnCondition = (arg: FnConditionArgs) => boolean
type ValidationType<T> = {
  relatedField?: string
  condition?: T
  fncondition?: FnCondition
}

type DropdownOptionWithFilterValue = DropdownOption & { valid?: any[] }
type GenericCondition = (string | number | boolean | Date)[]
export enum ComponentTypes {
  TEXT_INPUT_COMPONENT = 'textInput',
  TEXT_AREA_COMPONENT = 'textArea',
  CURRENCY_INPUT_COMPONENT = 'currencyInput',
  PERCENTAGE_INPUT_COMPONENT = 'percentageInput',
  DROPDOWN_SELECTOR_COMPONENT = 'dropDownSelector',
  RADIO_SELECTOR_COMPONENT = 'radioSelector',
  YES_NO_RADIO_SELECTOR_COMPONENT = 'yesNoRadioSelector',
  DATE_INPUT_COMPONENT = 'dateInput',
  YEAR_MONTH_INPUT_COMPONENT = 'yearMonthInput',
  TERM_COMPONENT = 'termInput',
  SEARCH_SELECTOR = 'searchSelector',
  DATE_RANGE_SELECTOR = 'dateRangeSelector',
  CHECKBOX_COMPONENT = 'checkbox',
  PHONE_COMPONENT = 'phone',
  MULTI_SEARCH_SELECT = 'multiSearchSelect',
}

// Constants
export const TEXT_INPUT_COMPONENT = ComponentTypes.TEXT_INPUT_COMPONENT
export const TEXT_AREA_COMPONENT = ComponentTypes.TEXT_AREA_COMPONENT
export const CURRENCY_INPUT_COMPONENT = ComponentTypes.CURRENCY_INPUT_COMPONENT
export const PERCENTAGE_INPUT_COMPONENT = ComponentTypes.PERCENTAGE_INPUT_COMPONENT
export const DROPDOWN_SELECTOR_COMPONENT = ComponentTypes.DROPDOWN_SELECTOR_COMPONENT
export const RADIO_SELECTOR_COMPONENT = ComponentTypes.RADIO_SELECTOR_COMPONENT
export const YES_NO_RADIO_SELECTOR_COMPONENT = ComponentTypes.YES_NO_RADIO_SELECTOR_COMPONENT
export const DATE_INPUT_COMPONENT = ComponentTypes.DATE_INPUT_COMPONENT
export const YEAR_MONTH_INPUT_COMPONENT = ComponentTypes.YEAR_MONTH_INPUT_COMPONENT
export const TERM_COMPONENT = ComponentTypes.TERM_COMPONENT
export const SEARCH_SELECTOR = ComponentTypes.SEARCH_SELECTOR
export const DATE_RANGE_SELECTOR = ComponentTypes.DATE_RANGE_SELECTOR
export const CHECKBOX_COMPONENT = ComponentTypes.CHECKBOX_COMPONENT
export const PHONE_COMPONENT = ComponentTypes.PHONE_COMPONENT
export const MULTI_SEARCH_SELECT = ComponentTypes.MULTI_SEARCH_SELECT

/**
 * NOTE: ONLY EDIT InputBuilderField directly
 * if the new prop is for ALL INPUT TYPES
 *
 * Otherwise, add to that specific type
 */
interface InputBuilderField<T = GenericCondition> {
  fieldName: string
  id: string
  label?: string
  variant?: Variant
  defaultValue?: string
  initialValue?: boolean | null | undefined
  customMissingField?: boolean
  fieldPrefix?: string
  disabled?: boolean
  showErrorsWithoutTouched?: boolean
  appSection?: MissingFieldsSections
  formSection?: string
  itemId?: string
  index?: number
  errorMessageInsert?: { [key: string]: string }
  validation?: ValidationType<T>
  fieldValidation?: AnySchema
  rows?: number
  missingFieldName?: string
  extra?: () => ReactNode | null
}

interface TextInput extends InputBuilderField {
  componentType: ComponentTypes.TEXT_INPUT_COMPONENT
  inputType?: string
  hasValue?: boolean
  iconName?: IconName
}

interface TextAreaInput extends InputBuilderField {
  componentType: ComponentTypes.TEXT_AREA_COMPONENT
  inputType?: string
  rows?: number
}

interface CurrencyInput extends InputBuilderField {
  componentType: ComponentTypes.CURRENCY_INPUT_COMPONENT
  tooltip?: string
  hasFailedVerification?: boolean
  secondaryText?: string
}

interface PercentageInput extends InputBuilderField {
  componentType: ComponentTypes.PERCENTAGE_INPUT_COMPONENT
}

interface DropdownInput extends InputBuilderField {
  componentType: ComponentTypes.DROPDOWN_SELECTOR_COMPONENT
  options?: DropdownOptionWithFilterValue[]
  optionFilterField?: string
  secondaryText?: string
}

interface RadioInput extends InputBuilderField {
  componentType: ComponentTypes.RADIO_SELECTOR_COMPONENT
  layout?: Layout
  options?: DropdownOptionWithFilterValue[]
}

interface YesNoRadioInput extends InputBuilderField {
  componentType: ComponentTypes.YES_NO_RADIO_SELECTOR_COMPONENT
  tooltip?: string
  opposite?: boolean
}

interface DateInput extends InputBuilderField {
  componentType: ComponentTypes.DATE_INPUT_COMPONENT
}

interface YearMonthInput extends InputBuilderField {
  componentType: ComponentTypes.YEAR_MONTH_INPUT_COMPONENT
}

interface Checkbox extends InputBuilderField {
  componentType: ComponentTypes.CHECKBOX_COMPONENT
}

interface TermInput extends InputBuilderField {
  componentType: ComponentTypes.TERM_COMPONENT
  onlyYears?: boolean
}

interface SearchSelectorInput extends InputBuilderField {
  componentType: ComponentTypes.SEARCH_SELECTOR
  data?: DropdownOption<string | GridSortItem>[] | OptionGroup[]
  isSearchable?: boolean
  useTagsOnMulti?: boolean
  isMulti?: boolean
  showClear?: boolean
  heightCap?: boolean
  showSelectAllOptions?: boolean
  displayMultiOptionsOnDisabled?: boolean
}

interface MultiSearchSelect extends InputBuilderField {
  componentType: ComponentTypes.MULTI_SEARCH_SELECT
  data?: string[]
  mappingFn?: (value: string, idx: number, array: unknown[]) => DropdownOption
  isSearchable?: boolean
}

interface DateRangeInput extends InputBuilderField {
  componentType: ComponentTypes.DATE_RANGE_SELECTOR
  onApplyFilter?: () => void
}

interface PhoneInput extends Omit<TextInput, 'componentType'> {
  componentType: ComponentTypes.PHONE_COMPONENT
}

export type InputBuilderProps =
  | TextInput
  | TextAreaInput
  | CurrencyInput
  | PercentageInput
  | DropdownInput
  | RadioInput
  | YesNoRadioInput
  | DateInput
  | TermInput
  | SearchSelectorInput
  | DateRangeInput
  | YearMonthInput
  | Checkbox
  | PhoneInput
  | MultiSearchSelect

export type FnConditionArgs = Record<string, boolean | number | string>

// splits a string by your chosen method (defaults to white space), concatenates the resulting split string,
// and sets case to Pascal case, i.e. "Fixed term" and "FiXeD    TERm " become "FixedTerm"
export const concatenateSplitString = (value: string, splitter?: string) => {
  const splitBy = splitter !== undefined ? splitter : ' '
  if (value.split(splitBy).length > 1) {
    const values = value.split(splitBy)
    let pascalCaseString = ''
    values?.forEach(
      (value) => (pascalCaseString += value[0] ? value[0].toUpperCase() + value.slice(1).toLowerCase() : ''),
    )

    return pascalCaseString
  }
  return value
}

// this function ensures that fields whose values have been removed get set to null
// as opposed to just being removed from the state
export const fieldParserFn = (value: any) => (value === '' ? null : value)

/**
 * This function takes a string value and attempts to parse it into an integer.
 * If the provided value is an empty string, it returns null instead.
 * This is useful when you are reading data that should be numeric, but might be represented as strings or might have missing values.
 * By using this function, you can ensure that empty strings are treated as null and other strings are properly converted to integers.
 *
 * @param value {string} - The string to be parsed into an integer. An empty string will be considered as null.
 *
 * @returns {null | number} - Returns the parsed integer if the input is a non-empty string, or null if the input is an empty string.
 */
export const numericParserFn = (value: string) => (value === '' ? null : parseInt(value))
