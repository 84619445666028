import { Action, CollectionKey, DecoratedTask, ExceptionTaskOrCollectionKey } from './CaseVerifications.types'

// 1.
// 2. Ensure impersonation checks have been performed on non face to face clients
// 3. Confirm whether clients have been seen face to face

//
// @TODO FRON-1378
//
// These will eventually be described by JSON. The list is incomplete
// but will suffice for the time being.
//

export enum Gate {
  UNKNOWN = '4c7119d6-54da-4b8f-9646-6eb501a2fee5',
  GATE_2_2 = '4795ece2-4d5c-45ff-969c-ddff800f9ce8',
  GATE_3_1_1 = 'fc9cae62-29d0-480f-ad8f-c8050d9162da',
  GATE_3_1_2 = '1cfcc1ee-1200-4348-98cf-bfdc902c7b06',
  GATE_3_1_3 = '453ef945-c1e7-4d60-b2af-c880f2a8f4c7',
  GATE_3_1_4 = 'da6c5111-37a9-4e8e-b4b1-0ba31b5288d8',
  GATE_3_2 = '6485401b-de35-479f-8bba-b3885964c645',
  GATE_3_2_1 = '88e78b05-a12c-45ec-b6fe-55d9cee9e5a2',
  GATE_3_2_2 = '4e3ac03e-c6a6-4504-849b-b4aee94b7225',
  GATE_3_2_3 = 'c9369465-3f51-4440-923c-fb024eebee81',
  GATE_3_3 = '2707a2c1-c5bf-47df-83c3-6ac722f30edd',
  GATE_3_4 = 'e272c0be-f340-4a77-a0ab-221f2de72917',
  GATE_3_4_1 = '691d8ef8-8331-11eb-8dcd-0242ac130003',
  GATE_3_4_2 = '3ab3666a-19cb-4169-b5bd-49a3d59b5183',
  GATE_3_4_3 = 'ed8aa316-c6cd-4e4f-971b-75a549dbc8a2',
  GATE_3_5_1 = '98b0d7d4-cf0f-4c6e-ac83-336e77dfbe4b',
  GATE_3_5_2 = '6f09cc3f-7656-41b8-becc-cef237b6851c',
  GATE_3_5_3 = 'afa45dde-afb5-11ea-b3de-0242ac130004',
  GATE_3_6 = '1603231f-4362-4770-81c9-eea2bfa740d2',
  GATE_3_6_1 = 'd7033c22-d616-4f20-bac3-4f96bf4825f5',
  GATE_3_6_2 = 'f96f5367-2ee1-4a9c-a40c-5320346779d9',
  GATE_3_7_1 = 'd1067db2-b239-4309-9c69-a35c2ca64945',
  GATE_3_7_2 = '20381c16-daa6-46d9-b7c2-cf1c0928032b',
  GATE_3_8 = '3bf5e3a6-09e7-44b9-89f6-ddc873a0dfd9',
  GATE_3_9 = '9eee9b49-4db5-4032-8241-98cb41723a84',
  GATE_4_1 = 'c8e370ea-d938-4517-8d8f-a09b2a54da33',
  GATE_4_2 = 'b4ba2510-75ef-4c80-a8c3-6dde09dd7f85',
  GATE_4_4 = '09d8e4df-2d38-4e7a-8998-75de7eb673cb',
  GATE_4_6 = 'c2134ba2-a4f4-4924-9488-9b1c06c08bb6',
  GATE_5 = 'e0b3fc85-058c-4327-ae8e-2192c6e904e3',
  GATE_5_1 = 'c1bf529c-b2c1-4e21-b683-67e97b2ed7af',
  GATE_6 = '56edb503-9029-4cca-9fca-69c2977e4d14',
  GATE_6_1 = 'b94e9874-dbba-44a6-a6d0-e3893b7e6a42',
  GATE_7 = '34639a77-a715-4198-80b8-ced215850520',
  GATE_7_1 = '0eaaa814-eab5-4477-96d5-d442c1c42bb3',
  GATE_8 = 'eea6416b-0fac-458d-a70a-fa5f7bc41eb5',
  GATE_8_1 = 'b7b214f7-6e19-406d-aa1b-5701298b351c',
  GATE_9 = 'c258e04f-fecd-4c6f-9a9c-d0f0d15c313e',
}

export const exceptionTaskAndCollectionTemplates: { [key in ExceptionTaskOrCollectionKey]: DecoratedTask } = {
  publishMortgageSuitabilityReport: {
    message: 'Publish mortgage suitability report',
    action: Action.Redirect,
    pathname: '/cases/:case_id/suitability/mortgage',
  },
  publishProtectionSuitabilityReport: {
    message: 'Publish protection suitability report',
    action: Action.Redirect,
    pathname: '/cases/:case_id/suitability/protection',
  },
}

export const collectionTemplates: {
  [key in CollectionKey]: DecoratedTask
} = {
  '4c7119d6-54da-4b8f-9646-6eb501a2fee5': {
    message: 'Unknown',
  },
  '6485401b-de35-479f-8bba-b3885964c645': {
    // Gate 3.2
    message: 'Complete client personal details',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
  },
  '88e78b05-a12c-45ec-b6fe-55d9cee9e5a2': {
    // Gate 3.2.1
    message: 'Complete client credit history section',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/:client_id/credit',
  },
  '4e3ac03e-c6a6-4504-849b-b4aee94b7225': {
    // Gate 3.2.2
    message: 'Complete client income section',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/:client_id/income',
  },
  'c9369465-3f51-4440-923c-fb024eebee81': {
    // Gate 3.2.3
    message: 'Complete client expenditure and budget section',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/outgoings',
  },
  '1603231f-4362-4770-81c9-eea2bfa740d2': {
    message: 'Sourcing reconciliation & validation',
  },
  'd1067db2-b239-4309-9c69-a35c2ca64945': {
    message: 'Complete mortgage suitability report',
    action: Action.Redirect,
    pathname: '/cases/:case_id/suitability/mortgage',
  },
  '20381c16-daa6-46d9-b7c2-cf1c0928032b': {
    message: 'Complete protection suitability report',
    action: Action.Redirect,
    pathname: '/cases/:case_id/suitability/protection',
  },
  '691d8ef8-8331-11eb-8dcd-0242ac130003': {
    // Gate 3.4.1
    message: 'Complete protection details',
    action: Action.Redirect,
    pathname: '/cases/:case_id/details/protection-requirements',
  },
  '2707a2c1-c5bf-47df-83c3-6ac722f30edd': {
    message: 'Complete property details',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/properties',
  },
  '98b0d7d4-cf0f-4c6e-ac83-336e77dfbe4b': {
    // Dealt with as part of RD-2462
    message: 'Complete mortgage details',
    action: Action.Redirect,
    pathname: '/cases/:case_id/products',
  },
  'fc9cae62-29d0-480f-ad8f-c8050d9162da': {
    message: 'Current case owner not permissioned for these products',
    hideWhenComplete: true,
  },
  'b4ba2510-75ef-4c80-a8c3-6dde09dd7f85': {
    message: 'KYC Verification required',
    hideWhenComplete: true,
  },
  'c8e370ea-d938-4517-8d8f-a09b2a54da33': {
    message: 'Complete ID verification ',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/:client_id/id-verification',
  },
  'e272c0be-f340-4a77-a0ab-221f2de72917': {
    // Gate 3.4
    message: 'Complete case details ',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/mortgage',
  },
  '3ab3666a-19cb-4169-b5bd-49a3d59b5183': {
    // Gate 3.4.2
    message: 'Complete borrowing requirements',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/mortgage',
  },
  'ed8aa316-c6cd-4e4f-971b-75a549dbc8a2': {
    // Gate 3.4.3
    message: 'Complete client preferences',
    action: Action.Redirect,
    pathname: '/cases/:case_id/details/preferences',
  },
  'afa45dde-afb5-11ea-b3de-0242ac130004': {
    // Gate 3.5.3
    message: 'Check all GI product fields are entered',
    action: Action.Redirect,
    pathname: '/cases/:case_id/home-insurance-products',
  },
  'f96f5367-2ee1-4a9c-a40c-5320346779d9': {
    // Gate 3.6.2
    message: 'Check recommendation details have been recorded',
    action: Action.Redirect,
    pathname: '/cases/:case_id/details/recommendation',
  },
  '3bf5e3a6-09e7-44b9-89f6-ddc873a0dfd9': {
    message: 'Multiple flags present on case. Compliance Review may be required',
    hideWhenComplete: true,
  },
  'da46dac2-6863-4833-b065-0a104e87e2b0': {
    // Gate 4.5.1
    message: 'Complete additional client details for Application',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
  },
  '82683f28-6ef3-4510-8f9b-8f903f053b52': {
    // Gate 4.5.2
    message: 'Complete additional income details for Application',
    action: Action.Redirect,
    pathname: '/cases/:case_id/fact-find/:client_id/income',
  },
  'b0a8fa92-7eea-48dd-b202-51e745857a88': {
    // Gate 4.5.3
    message: 'Please upload letter of gift for gifted deposits',
    action: Action.Redirect,
    pathname: '/cases/:case_id/documents',
  },
  'eea6416b-0fac-458d-a70a-fa5f7bc41eb5': {
    // Gate 8
    message: 'Complete Protection SR and Product Policy Numbers',
    action: Action.Redirect,
    pathname: '/cases/:case_id/protection/products',
  },
}

export const getVerificationTemplates = () => {
  return {
    '1de07baa-e0de-496c-8423-acd63d3ab612': {
      message: 'Confirm if client is potentially vulnerable customer',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '35383859-5c8d-460f-8bc5-9c6df314c632': {
      message: 'Please provide reason(s) your client may be potentially vulnerable',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    'd5993bb4-dd5b-41c3-b7ed-1b76e58c4749': {
      message: 'Missing debt to be consolidated',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'b55a82c8-d7e7-4266-84e2-d15c270de492': {
      message: 'Complete protection suitability report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/protection',
    },
    '09d4b8bc-8030-11eb-9439-0242ac130002': {
      message: 'Record if client has/has not been advised with regards to inheritance tax',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/recommendation',
    },
    '03ef5cc0-8032-11eb-9439-0242ac130002': {
      message: 'Record if client has/has not insisted on a particular outcome despite conflicting advice.',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '3764a1fa-8caa-4e48-8bd4-6db9c7903c63': {
      message: 'Record any client provided advice limits',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '15f7068f-411c-420a-b6fd-fa269f47de2a': {
      message: 'Record the circumstances for protection',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    // == case details -> existing protection ==
    'de90ffb3-983d-4cc4-95b7-808aa024ea62': {
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/existing-protection',
    },
    '20b843c1-8259-4ada-b8c4-148a9bf89db5': {
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/existing-protection',
    },
    // == case details -> shortfall-analysis ==
    '412e6d30-4f6d-40fa-b3da-1110b4ff6ec9': {
      message:
        'Check client has specified the scenario and existing policy flag on life and critical illness requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '17c5762f-8b6a-481c-a7bc-c93390866dc8': {
      message: 'Check client has given reasons for limiting protection',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'e4ddafd8-c1a4-4025-987d-0d1d72e95734': {
      message: 'Check client has provided protection requirement circumstances',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '5a49e5e1-d044-4173-b401-eca301d6a911': {
      message: 'Check client has specified the existing policy flag on private medical insurance requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'e0d5be6b-c8ea-4dd7-9eae-0bc83e39eb77': {
      message: 'Check client has given a reason for the coverage on private medical insurance requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '778c6bb1-9e6c-4b37-86f9-42be1f4678b8': {
      message:
        'Check client has given a reason for the cover amount, if there is one, on life and critical illness requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '20f104b7-174a-444d-af6e-42dd925067eb': {
      message:
        'Check client has given a reason for the term length, if there is one, on life and critical illness requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '92373b1a-bcf1-4245-911b-4c29e4da93f0': {
      message:
        'Check client has specified the scenario and existing policy flag on family income benefits requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '0a981b6a-aa31-44e1-8dd8-33bde4fc8fc8': {
      message:
        'Check client has given a reason for the cover amount, if there is one, on family income benefits requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '63c384f0-6055-4250-80d6-38d8693fc011': {
      message:
        'Check client has given a reason for the term length, if there is one, on family income benefits requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '655493d0-c470-43db-98e7-40a3df28ee37': {
      message: 'Check client has specified the scenario and existing policy flag on income protection requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '7ff21b67-3a22-49cb-ae5c-88d075a60a1a': {
      message:
        'Check client has given a reason for the cover amount, if there is one, on income protection requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '08420213-d4fc-4d15-bc6a-97a15054a74a': {
      message:
        'Check client has given a reason for the term length, if there is one, on income protection requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'f7c61ecb-ff6b-4c32-8bf1-8341d1722e30': {
      message: 'Check client has specified the existing policy flag on whole of life requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '26adc4f3-ba0d-4f98-a78f-6181e58ef0a7': {
      message: 'Check client has given a reason for the cover amount, if there is one, on whole of life requirements',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'f4b56f02-80b2-11eb-9439-0242ac130002': {
      message: 'Record the required funeral needs lump sum amount',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'a6d13434-c20b-442e-8a7a-881d01274bbc': {
      message: 'Check client has specified an explanation as to the reasoning for funeral needs',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'a82870e8-80b3-11eb-9439-0242ac130002': {
      message: 'Record if funeral shortfall needs are met by preexisting policies',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '82ebd963-95bf-4e9d-8b96-96e69a8cb91b': {
      message: 'Specify the death lump sum term',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '9faabe14-c68f-4fbc-b72c-baa2a4c10009': {
      message: 'Provide an explanation as to the death lump sum term',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'a8b629e8-80b2-11eb-9439-0242ac130002': {
      message: 'Specify whether death lump sum shortfall needs are met by preexisting policies',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'd68d625b-08b0-43a9-9a98-209ac5745d9b': {
      message: 'Enter the prefered death income term',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '78ab77f6-7c22-11eb-9439-0242ac130002': {
      message: 'Provide an explanation as to the prefered death need term length',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '3c3998fe-80b2-11eb-9439-0242ac130002': {
      message: 'Record if death income shortfall needs are met by preexisting policies',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'ff96366b-a234-4001-b177-a328abcfba94': {
      message: 'Enter the preferred illness income term',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '5674053a-e675-475b-93a7-eac7d0fc98e2': {
      message: 'Provide an explanation as to the preferred illness deferment term',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '0f0d8607-1fce-4bee-87ff-aff9ae7b2a26': {
      message: 'Provide an explanation as to the reasoning for the illness need term length(s)',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    '506b21bf-6666-4e9f-8696-9d788ab135bc': {
      message: 'Check client has/has not had illness needs met by preexisting policies',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    // == case details -> recommendation ==
    'b21c1338-6d80-49cb-9cad-d6382708bb26': {
      message: 'Record recommendation with respect to placing protection benefit in trust',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/recommendation',
    },
    'd6b2daf0-802e-11eb-9439-0242ac130002': {
      message: 'Record details of recommendation with respect to placing protection benefits in trust',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/recommendation',
    },
    '73b4345a-a632-4655-b4b0-5bbf18ed6a45': {
      message: 'Please provide further details about the recommended trust',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/recommendation',
    },
    // == others ==
    '7d268706-343a-4b38-8bc7-28acd5fc3ceb': {
      message: 'Check rebroke decisions have been explained',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/protection',
    },
    'f458e0a5-6dda-4893-9831-efc0eaf41ee3': {
      message: 'Check that policy number is available for all recommended products',
      action: Action.Redirect,
      pathname: '/cases/:case_id/protection/products',
    },
    'b64e9fc8-3581-47da-9016-542c531cb2c4': {
      message: 'Check product recommendation justification is available for recommended products',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/protection',
    },
    'a44e05d4-64bc-4a51-8fe4-cf1def3c298d': {
      message: 'Check client has explained any shortfalls that remain',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/protection',
    },
    '71d9a2ba-832d-11eb-8dcd-0242ac130003': {
      message: 'Recommend at least one protection product',
      action: Action.Redirect,
      pathname: '/cases/:case_id/protection/products',
    },
    '8a7b2bb0-2a2f-4a96-b5b5-f5f4b7c99f7d': {
      message: 'Add title ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '91589e44-f3f4-46e9-8c7e-6132d7212d68': {
      message: 'Add date of birth ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '3a135ecf-badb-44f2-bc58-b07e6ba3242f': {
      message: 'Add marital status ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    'af6831a1-d245-49c1-aae4-a19fe3ef7cb8': {
      message: 'Add current correspondence address ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '6cf64d8f-859e-46f5-8226-5333292296b2': {
      message: 'Check address history ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '59c8fd23-fc18-44dd-bd82-117e7eb9fa3b': {
      message: 'Current client addresses is incomplete ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    //come back to line 110
    '83be9147-91cf-4bce-844c-b6db5d1c4bf3': {
      message: 'Add three years of address history ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    'f10faa7d-08d6-4d9b-ae65-2ed52c124a99': {
      message: 'Add expected retirement age ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '8f854751-4152-43fc-a269-8be4fd5b35d1': {
      message: 'Add nationality ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '6be4c226-47b2-4a25-af25-49a53cd1c82b': {
      message: 'Confirm tax rate ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '52affbdf-7076-41a6-a980-db30c11f2f0d': {
      message: 'Check dependants form ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '88527420-3d19-422d-968d-b6e5f0549ed9': {
      message: 'Check deposits form ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '4a868a1e-a3ae-4c6c-b3ed-52f251e27c46': {
      message: 'Add company details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '4d4fdb5c-bd61-432d-a18e-1dd17d0fd554': {
      message: 'Application must have at least one set of bank details',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '45488490-5a12-4017-8785-3dbe5aa38bce': {
      message: 'Check commitments ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '63368740-0a3d-41f6-b114-091ac52b68df': {
      message: 'Check employer contact details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    '86a8a9b8-c783-4f73-a452-1a7792ca4eb0': {
      message: 'Check employer name',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    '1a942d77-2bcd-4f20-a7c5-fd57d62a27a1': {
      message: 'Confirm any previous repossessions ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '5c8b97d0-0ab6-42e8-acf1-18cfea89e143': {
      message: 'Add CCJ details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'cc59a169-8f91-4209-901b-73dc51d03fb2': {
      message: 'Add default details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'f572bb29-21a0-4716-9a72-a557c9f2cde5': {
      message: 'Add IVA details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'bf522e92-aa1d-43f1-a0fb-4669472fd87d': {
      message: 'Add arrears details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'd7dc4595-9006-4efc-bc40-26a12b624577': {
      message: 'Add bankruptcy details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'a9cef192-0791-44f3-b34b-5d4e37ecc524': {
      message: 'Add debt management plan details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '07b3cc86-eaad-488a-b292-0760be3a4284': {
      message: 'Check self-employment basis ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    'b1d48c3c-676b-40e7-bf8f-04dbf2bf1af1': {
      message: 'Add self-employment address ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    'c8730791-7a36-4dba-8e49-7507e87cf1ba': {
      message: 'Add number of years accounts available ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    '538093c9-bdef-464c-ae78-7dba75cb4a81': {
      message: 'Add taxable income last year ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    '576b7485-4ae4-4c62-b290-44f8459a21e0': {
      message: 'Add taxable income two years ago ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    '3f95dd5e-dc6b-45c0-a182-2dfa262f8575': {
      message: 'Add taxable income three years ago ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    '5a635f18-2f97-4167-ac62-5709d1fe57f0': {
      message: 'Check self-employment % share last year ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    '66ac7972-009b-4093-8091-47023fa417d5': {
      message: 'Check self-employment % share two years ago ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    'fae864d0-0336-46f6-889a-5599f10d4ea3': {
      message: 'Check self-employment % share three years ago ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    'ae1c1a45-3d10-49a1-8b90-d5e00f8799e8': {
      message: 'Agreed budget greater than disposable income ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'a5fbd96e-eafc-4c51-8636-2276ad34ceb4': {
      message: 'Monthly net income not supported by annual income ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    'ff437b38-553a-4749-8ca0-695c02763b19': {
      message: 'Check GI property has number of bedrooms set',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'f896cb49-59c5-4b9c-bfe5-f120d62bfecf': {
      message: 'Check GI property has sub type set',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '1256e8d2-3006-4c1a-b568-82e9c0b65c48': {
      message: 'Complete property to be sold details',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'd25183b2-217d-4332-ae5d-61c784d1b3e2': {
      message: 'Add valuation for property to be sold',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '07f64c6e-b8b1-47ad-a716-b16b4d7a4b8b': {
      message: 'Confirm if current lender has proposed a new product',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '3192cd8d-9797-44fb-a924-97e557ffbfc5': {
      message: 'Add client preference for repayment percentage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    'fbfcb0ea-78a1-43c8-bc69-c83225cdb550': {
      message: 'Add client preference for tracker',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    'de277f43-a520-4e45-bccf-d88b343b7722': {
      message: 'Add interest only repayment strategy details',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '234cc4fa-9ad2-421f-a300-992565715b27': {
      message: 'Add client preference for fixed rate',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '6ac78159-4892-46ef-8572-be78ee6912e6': {
      message: 'Add client preference for early year discount',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    'd89629bb-cc54-48e4-858c-8cab432da745': {
      message: 'Add client preference for term',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '95b21230-1e1b-4e79-98e1-a1f0e51633f9': {
      message: 'Complete property details',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'e189e27d-7918-486d-9ef9-5f7ddb26449d': {
      // come back to this one - make sure that it's being set somewhere because we don't have a proper input for it
      message: 'Add planned property purchase price',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details',
    },
    'c90746f3-953c-4d86-b9f7-170197a2d622': {
      message: 'Add client preference for cashback',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '47703272-5d30-4294-af39-7941592f0cba': {
      message: 'Add client preference for fixed period length',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '53044d60-33c9-47b3-b66a-56a82bd280e5': {
      message: 'Add client preference for no ERC',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '6d0a10f8-0637-43fe-85f1-5b57386b5dd6': {
      message: 'Add client preference for no tie in',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '52db2330-aa7f-4749-a5e3-e859905992f8': {
      message: 'Add client preference for speed of completion',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    'ee788625-d3e3-4889-849d-e0e2d66af8b7': {
      message: 'Add client preference for adding fees to loan',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '8556fd11-5be3-4d4f-858d-28367227a23c': {
      message: 'Add client preference for varying repayment amounts',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    'fbcd7731-889e-463a-bcfa-a9e07ad0e119': {
      message: 'Add client preference for payment holidays',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    'eb7d1179-0c21-487c-8055-0a144c6714cb': {
      message: 'Add client preference for linked finances',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '0b8da2f6-e337-4c3c-8925-1eb52945b9dd': {
      message: 'Add client preference for free conveyancing',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '8d755443-1385-4116-bc35-8a332769098d': {
      message: 'Add client preference for free valuations',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    'c26d31f6-9477-4be5-9aee-8fcacabacbba': {
      //Ignore lines 509 - 563 until we confirm that RD-2462 covers it
      message: 'Mortgage product id missing',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    'd3529e3f-9664-4356-849e-788d151b0c5f': {
      message: 'Add mortgage amount for proposed mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    'f3d8f8bf-f272-4d13-8c39-c52a162f336e': {
      message: 'Additional details required for proposed mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    'a13a9143-115c-4c34-bedb-aa8cba05a1f8': {
      message: 'Add lender name for proposed mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '62eadab4-423f-472d-96dd-dc1a6874cc25': {
      message: 'Add mortgage class for proposed mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    'e972bd4c-e481-4bde-b50d-7df25f3e67fa': {
      message: 'Add valuation fee for proposed mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    'e836b333-c447-4754-8952-1d884a0651a5': {
      message: 'Add arrangement fee for proposed mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    'ff645601-9a5f-4a83-91c4-a4512e1c10ca': {
      message: 'Add booking fee for proposed mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '2a1be837-f901-4968-9cc1-54fd49c39340': {
      message: 'Add CHAPS fee for proposed mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    'ce23e4de-6f37-4af4-a801-7433c3528415': {
      message: 'Add deeds release fee for existing mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    'f37c07b2-8f15-4caa-991f-11c91900140e': {
      message: 'Add initial rate end date for proposed mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '9eb7f388-39a3-40dd-8603-50924218e152': {
      message: 'Confirm if early repayment charges apply to existing mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '30f54685-ec36-4ed5-9fa2-0a1c455d2744': {
      message: 'Add property location for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'c58ef454-7c96-4ced-acf1-30506bf7d847': {
      message: 'Add wall type for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '4b7e6e7b-7cd2-470b-a36e-a7aab5d20763': {
      message: 'Add roof type for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '6702f721-775a-41fb-b46c-e388a8e7a75a': {
      message: 'Add year built for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'ab676e82-1d31-45c4-b042-24f7a0a239f1': {
      message: 'Add purchase price for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'e4122491-986c-4250-9a8e-cab42731009c': {
      message: 'Add valuation for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '6d122d2d-04d6-46fa-95b3-d2d919a8aec3': {
      message: 'Add property type for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'a158b12d-0291-400a-aeb2-3eebd8cf3198': {
      message: 'Add tenure for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'dd86c0a9-ba0e-45b0-8afd-3a97c2fd7e35': {
      message: 'Add property use for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '78eb8384-9c95-4194-ada1-fe79b2b1bd90': {
      message: 'Add builder name for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '75b7e4db-4997-4c80-9b9f-f0ced7ad63eb': {
      message: 'Add builder registered scheme for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '6a99cf22-0794-4543-99de-045f3fb60ddb': {
      message: 'Add number of floors in property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '605ec4f1-47ed-4739-b62e-a346e012491e': {
      message: 'Add floor number for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '59ee1255-8579-4207-b361-a92bcca61915': {
      message: 'Confirm if clients intend to run a business from property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '6e25da8e-b9e3-4ab7-aa56-3afe6b7b9dff': {
      message: 'Add leasehold length for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '510c7731-fd74-4ed2-9103-3489cb12aa57': {
      message: 'Add ground rent for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'b2692937-b875-4d77-b8a3-30a1bb213d77': {
      message: 'Add service charge for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '89e5e19e-6048-4d3d-a106-8b85fd4d3c7b': {
      message: 'Add flying freehold percentage for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'e14dbffe-3d5b-4378-8571-033c1a92936e': {
      message: 'Deliver updated disclosure document ',
      action: Action.IDD,
    },
    '35f8041f-c427-41a7-b092-8f6311bf6ab6': {
      message: 'Please complete ID checks for all interested parties',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/id-verification',
    },
    'e876254f-4165-4b59-a0f8-d8dca09c9f54': {
      message: 'Confirm if impersonation checks have been performed',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    'ecbc1a9b-9161-42e8-86dc-7fd062a13d02': {
      message: 'Add details of impersonation check',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    'd5b1b220-b7af-4558-990a-721e7a9bd75f': {
      message: 'Confirm whether clients have been seen face to face',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '2ccd8f67-3491-49c7-bf63-5e1c7c2e49f3': {
      message: 'eIDV check has expired for',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/id-verification',
    },
    '939e93dd-0e6f-4880-a6d2-5f753644809b': {
      message: 'Confirm if clients have previously owned property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    'a6afa2b7-42d3-46cc-97c5-fdf8c8907e19': {
      message: 'Confirm if client has lived in property to be mortgaged',
      action: Action.Redirect,
      pathname: '/cases/:case_id/properties/edit/:property_id',
    },
    '379fe0a3-dae4-4543-a9b6-b8cd16f9a768': {
      message: 'Confirm if client or family relative will reside in security address',
      action: Action.Redirect,
      pathname: '/cases/:case_id/properties/edit/:property_id',
    },
    '56ceeca7-0b28-4d61-96a9-ed0cc0a67c7c': {
      message: 'Confirm if case is Limited Company BTL',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    'edf02f0b-c7e7-4b7a-9d07-dbe1266e6e58': {
      message: 'Confirm if client has other buy-to-let properties',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '7fa91a15-6d4d-4a6c-8a6e-8c0f45f2371b': {
      message: 'Rerun ID check with new details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/id-verification',
    },
    'c13bd0fa-beee-4634-89c8-4fc136c94ad1': {
      message: 'Add gifted deposit donor details ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '260631d7-1874-4c65-85d5-c983d920e35a': {
      message: 'Recommend a shortlisted mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    'de9651fa-965c-4071-9559-9782bb39d10c': {
      //leave to check if covered by VDaug changes in RD-2624
      message: 'Mortgage amount cannot be higher than property valuation',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '6edc5db0-896d-11ea-bc55-0242ac130003': {
      message: 'Add standard variable rate to non-term mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '89a91ebd-688a-478a-9dc3-723c677fc56f': {
      message: 'Add monthly management expenses for property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '8b154e7c-0bf7-44c8-b21f-81276d2f2dd1': {
      message: 'Outgoings must include service charge or ground rent set for Leasehold property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '14675e56-1ddb-4f01-be81-024f67588de7': {
      message: 'Shared equity rent must be less than or equal to outgoings mortgage/rent ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'e24b93d9-8a84-4ad3-aa24-1992062ac08b': {
      message: 'Confirm if this case is introduced business',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    'bbaa3ca1-3578-41ba-9b8b-ede084d8934c': {
      message: 'Add introducer details to the case',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '6f4cb8a4-b264-43f6-b594-dce2a036e1b6': {
      message: 'Check client agreed budget has been set ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '69f9e4c1-3203-49af-8176-c1b18d212877': {
      message: 'Check client deposit type ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '3f425e12-7e3a-4982-93a0-b23ef15f1cf2': {
      message: 'Check client employment status has been set ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    '03a1f11b-c975-4449-a4b5-a885060a9dab': {
      message: 'Check net monthly income is set ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/income',
    },
    'a4d5b1ac-d387-479a-a928-4f0ff5149435': {
      message: 'Please record an agreed budget for the clients ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '88f985f0-7b24-481b-be12-0e8c5d164014': {
      message:
        'Sum of client deposits and equity should be greater than or equal to the property value minus the proposed mortgage amount',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '5ec29996-5760-11eb-ae93-0242ac130002': {
      // Check if covered as part of RD-2624
      message: 'Check period length or end date for early repayment charge periods is set',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '5c538531-0ae5-41d5-83ff-4d2602455ddd': {
      message: 'Property has been previously repossessed/surrendered - Please enter details',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'f091a106-60ba-11eb-ae93-0242ac130002': {
      message: 'Add phone number for limited company',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    'f685c966-60ba-11eb-ae93-0242ac130002': {
      message: 'Add email for limited company',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    'e99902a4-60ba-11eb-ae93-0242ac130002': {
      message: 'Add SIC for limited company',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '4476f4bc-566e-11eb-ae93-0242ac130002': {
      message: 'Check unencumbered status of property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    'afd2ba94-53f2-11eb-ae93-0242ac130002': {
      message: 'Add outgoings for water',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'a771746c-53f2-11eb-ae93-0242ac130002': {
      message: 'Add outgoings for electricity, gas and oil',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '92005882-53f2-11eb-ae93-0242ac130002': {
      message: 'Add outgoings for other mortgages and rent',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'b647ea34-53f2-11eb-ae93-0242ac130002': {
      message: 'Add outgoings for communications',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'be175754-53f2-11eb-ae93-0242ac130002': {
      message: 'Add outgoings for television license',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'b88d2170-53f1-11eb-ae93-0242ac130002': {
      message: 'Add outgoings for council tax',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '91078a5c-5138-11eb-ae93-0242ac130002': {
      message: 'Add outgoings for car costs',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '85a17492-51de-11eb-ae93-0242ac130002': {
      message: 'Add outgoings for other transport costs',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '1394181a-5138-11eb-ae93-0242ac130002': {
      message: 'Add outgoings for service charge / ground rent',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'f117637a-f4ca-4ea5-a346-db6c78ff1e6c': {
      message: 'Please upload letter of gift for gifted deposits',
      action: Action.Redirect,
      pathname: '/cases/:case_id/documents',
    },
    '051e39c7-490e-4a66-a5f4-0f13d5af3256': {
      message: 'Record details of Limited Company directors',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '07014b47-8497-46d9-a0cc-b11c24028a0a': {
      message: 'Record details of GI policy premium',
      action: Action.Redirect,
      pathname: '/cases/:case_id/home-insurance-products',
    },
    '102bdcfb-4b0d-4002-9550-a045db1792b7': {
      message: 'Specify security property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '107869cb-8aa9-48fb-8dfc-e50a710bf693': {
      message: 'Specify strategy for payment of ERC',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '10a0b101-dcf5-40e7-9a8c-6c9e5a5c31dd': {
      message: 'Record details of GI buildings cover level',
      action: Action.Redirect,
      pathname: '/cases/:case_id/home-insurance-products',
    },
    '10d21169-2cbe-4b33-a75a-6618537dc664': {
      message: 'Enter reason for recommendation in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '18c315c4-374f-4f30-94a2-4c821994a5a8': {
      message: 'Unexpected issue, please contact Acre support',
      // Action should raise support ticket.
    },
    '1c5adeab-a87b-4cc6-891c-2f9765c21e91': {
      message: 'Mismatch between property price, deposits and mortgage amount',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '20d26286-7021-4e33-a4d9-0964a94762d8': {
      message: 'Check one or more GI products have valid statuses',
      action: Action.Redirect,
      pathname: '/cases/:case_id/home-insurance-products',
    },
    '270826fc-f565-4e58-ab5c-35ce210ac103': {
      message: 'PEP Flag present on case.  Compliance Review will be required',
      // Action should send for review.
    },
    '2754e797-110f-4aa0-bc3f-9ed3edcb7743': {
      message: 'Add purpose for additional loan',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '33158ffc-1705-4804-af31-877437b0a55f': {
      message: 'Unexpected issue, please contact Acre support',
      // Action should raise support ticket.
    },
    '335ff476-425d-412d-b76c-735968f5fe3d': {
      message: 'Enter BTL Void payment strategy in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '3450f777-1900-4cdb-a95f-58cb3fee341f': {
      message: 'Enter reason for cost increase in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '37ec1d97-8289-41ef-acd2-433a570e9f18': {
      message: 'Case owner does not have appropriate competencies.  Please reassign case',
      action: Action.Redirect,
      // Action would ideally open reassignment dialog.
      pathname: '/cases/:case_id/overview',
    },
    '382e0668-69cf-414b-a8c0-d9a9b3fedb5b': {
      message: 'Enter help to buy repayment strategy in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '392a68a2-5f36-11eb-ae93-0242ac130002': {
      message: 'Provide details of mortgage for current property',
      action: Action.Redirect,
      //Ideally would link directly to relevant property.
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '395cbdc0-6959-4b96-8b1e-fe20c8fd31f5': {
      message: 'Additional details required for unearned income for',
      action: Action.Redirect,
      // Ideally would link directly to relevant income.
      pathname: '/cases/:case_id/fact-find/:client-id/income',
    },
    '39ed369d-15fb-42c0-a179-d1fb3d132a13': {
      message: 'Add client preference for overpayment',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/preferences',
    },
    '46139a9e-1d82-4dd0-9ddf-376e930a6b5d': {
      message: 'Case owner does not have appropriate competencies for protection recommendation.  Please reassign case',
      action: Action.Redirect,
      // Action would ideally open reassignment dialog.
      pathname: '/cases/:case_id/overview',
    },
    '51d3c71a-9665-49cb-87f0-10ef89b09f35': {
      message: 'Enter additional borrowing details in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '55b58e24-1c71-467a-95e8-6dfe0a4e2a78': {
      message: 'Specify a mortgage start date',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '19409cbb-39e9-4015-b0b1-69399841e56f': {
      message: 'Specify outstanding balance date on existing mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '5977f29a-f164-4476-91d8-c35ff16a2650': {
      // Don't mention FC.
      message: 'Flag present on case.  Compliance Review will be required',
      // Action should send for Review.
    },
    '59aa9000-530c-4463-9d5a-f4926cf08a8b': {
      message: 'Enter reason for term increase in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '6a948a3b-e289-4181-88e9-b32d8a72035a': {
      message: 'Specify term of mortgage',
      action: Action.Redirect,
      // Need mechanism to link directly to correct mortgage
      pathname: '/cases/:case_id/products',
    },
    '6cd91878-b1ca-4029-bfd3-89262c5f935d': {
      // Mismatch between clients on case and clients on recommended mortgage.
      message: 'Unexpected issue, please contact Acre support',
    },
    '76e15ec8-072a-465e-a01c-cb5648affc9e': {
      message: 'Mismatch between current mortgage value, proposed mortgage value, deposits and additional borrowing',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    '7a60dc16-afb4-11ea-b3de-0242ac130004': {
      // GI missing associated property.
      message: 'Unexpected issue, please contact Acre support',
    },
    '7c88c0e8-175a-474e-bcb5-6b09615027e3': {
      message: 'Enter reason for term into retirement in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '7d38ef29-293b-4b6e-8126-d06251456128': {
      message: 'Enter an introduction in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '89956183-356c-463e-964f-f0f380800b73': {
      // Mortgage fee total missing
      message: 'Unexpected issue, please contact Acre support',
    },
    '8cf5ae8f-289b-4337-a016-402055711a9a': {
      message: 'Enter proc fee details for recommended mortgage',
      action: Action.Redirect,
      // Need mechanism to link directly to correct mortgage;  consider how this is fixed for sourced mortgages.
      pathname: '/cases/:case_id/products',
    },
    '8f7c3fbc-c2e9-45dc-9635-3357cd573033': {
      message: 'Add shared equity repayment details for shared equity property',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/properties',
    },
    '979dfdda-76b0-4740-b1bd-6dfb9f7a29bb': {
      message: 'Case will require pre-sales review prior to recommendation',
      // Action should allow to send for review at appropriate point.
      action: Action.Redirect,
      pathname: '/cases/:case_id/overview',
    },
    '99df172c-d26c-4e10-926a-de443d30b6a9': {
      message: 'Enter reason for retirement interest only in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '9aecef99-a36f-4efc-b480-8b9d99fdac57': {
      message: 'Agreed budget exceeds clients disposable income',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '9dc1f4cd-188b-4d5c-9ea4-dcafba0218c6': {
      message: 'Record details of GI product term',
      action: Action.Redirect,
      pathname: '/cases/:case_id/home-insurance-products',
    },
    '9eda1bab-e695-437b-bb8c-6a1f7c390726': {
      message: 'Enter reason for disparity in equity in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    'ab16aca8-7c2a-11eb-9439-0242ac130002': {
      // Protection sourcing error
      message: 'Unexpected issue, please contact Acre support',
    },
    'acc1722c-fadc-4e34-b7e3-996b3076eebc': {
      message: 'Enter reason for no protection recommendation',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/protection',
    },
    'b8c850c1-1405-4d3f-8f8d-bd13a528a615': {
      message: 'Case owner does not have appropriate competencies for GI recommendation.  Please reassign case',
      action: Action.Redirect,
      // Action would ideally open reassignment dialog.
      pathname: '/cases/:case_id/overview',
    },
    'c13f1116-e86d-4a90-85dc-d6f68056243b': {
      message: 'Mismatch between current mortgage value, proposed mortgage value, deposits and additional borrowing',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    'cf430f44-d21e-4041-8ff6-5f3cf5796f7c': {
      message: 'Debt Con Flag present on case.  Compliance Review will be required',
      // Action should send for review.
    },
    'd4b3b396-b0e0-4fd9-9eec-efadb9fe7785': {
      message: 'Add details of how client has limited the provided protection advice',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/protection-requirements',
    },
    'd50644c2-2e57-4c29-bd85-e4bfd97cdaaf': {
      message: 'Enter reason for debt consolidation in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    'd57a4003-709d-4004-bd8b-c59837d912c7': {
      message: 'High fees present on case. Compliance review will be required',
      // Action should send for review.
    },
    'd590bb05-0ba8-4543-ba3c-b2e839118424': {
      // GI Provider missing
      message: 'Unexpected issue, please contact Acre support',
    },
    'd73ed8c5-0474-46a0-8971-2b53db03604d': {
      message: 'Enter outstanding balance for current mortgage',
      action: Action.Redirect,
      // Need mechanism to link directly to correct mortgage;  consider how this is fixed for sourced mortgages.
      pathname: '/cases/:case_id/products',
    },
    'd7e04033-2d6a-4de5-8fb8-11bc7442eae7': {
      message: 'Record whether client has accepted protection advice',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/mortgage',
    },
    'd7e04033-2d6a-4de5-8fb8-11bc7442eae7/protection_referral': {
      message: 'Record whether client has accepted protection advice',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/protection',
    },
    'd959961b-bbba-46ca-81c6-f6f447e72041': {
      message: 'Enter reason for affordability in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    'dd18e02a-8b1e-4404-b8c2-5fdde58720b7': {
      message: 'Flag present on case.  Compliance Review will be required',
      // Action should send for review.
    },
    'dd197157-da9e-4135-9b77-085416335c39': {
      message: 'Include mortgage application date when uploading mortgage application form',
      action: Action.Redirect,
      // Action should open MAF upload dialog.
      pathname: '/cases/:case_id/documents',
    },
    'e0a3f8a6-5a4e-11eb-ae93-0242ac130002': {
      // Mismatch between target property and mortgage property
      message: 'Unexpected issue, please contact Acre support',
    },
    'f403dccd-54b8-4200-a616-a4f3bc077010': {
      message: 'Record details of GI contents cover level',
      action: Action.Redirect,
      pathname: '/cases/:case_id/home-insurance-products',
    },
    'fa3e4e1c-77c1-40d1-8026-580b5ca72f4e': {
      message: 'Nationality Flag present on case.  Compliance Review will be required',
      // Action should send for review.
    },
    'fd7fa9bb-fefc-46d7-a1ac-4be76db4f779': {
      message: 'Enter reason for term in Suitability Report',
      action: Action.Redirect,
      pathname: '/cases/:case_id/suitability/mortgage',
    },
    '712d4f12-6b40-4a22-a825-1c35b01807b9': {
      // Gate 3.2
      message: 'Confirm client credit refusal status',
      action: Action.Redirect,
      // Action should go directly to public records section
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'e50a8168-39e6-4efb-9686-b06fcda4a869': {
      // Gate 3.2
      message: 'Confirm details of client credit refusal',
      action: Action.Redirect,
      // Action should go directly to public records section
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '8306e6b6-3854-46b1-91a0-b1b3c84a2952': {
      message: 'Enter arrears balance on debt',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '64bd4831-dced-4dfd-b949-57b7b96d91b8': {
      message: 'Enter max arrears months on debt',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '0c103cb9-2a9f-4936-a7ce-86beb616f330': {
      message: 'Add credit type for arrears',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '6350665c-0fcb-4758-995f-739e52e47a18': {
      message: 'Add creditor name for arrears',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '76c75968-530e-4126-9f1f-419dc3fad906': {
      message: 'Add date arrears cleared on debts',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '70f31fd2-63f5-43c6-bbf5-d0bd45892d16': {
      message: 'Confirm if arrears have been cleared',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'bad66433-6c2c-4663-baca-358a8d2e09c1': {
      message: 'Add arrears date cleared on debts',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'a5a61d7b-25cf-4ea8-80de-930e92e77f17': {
      message: 'Date of last arrears should be before the arrears cleared date',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'c68cbfd4-6e6d-4caa-9146-0eeddc5f1299': {
      message: 'Add default date registered on debts',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '0f12f92a-fe52-4f56-9d8a-818be35ed2fa': {
      message: 'Require date satisfied on defaulted debt, satisfied date should be after date registered',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '3b652069-7251-4ec7-9ea8-787f579e1e44': {
      message: 'Add default balance on debts',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'e50d04aa-0758-4af2-a0e7-35df18970d79': {
      message: 'Add creditor name for defaults',
      // deprecated field
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '787c2e5a-ffeb-4cff-b39d-869653fc8187': {
      message: 'Confirm date bankruptcy registered',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '7fefa140-28d4-48ff-8c33-1e839e16ef70': {
      message: 'Confirm bankruptcy court name',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '6535f2ac-d7e3-436c-b8de-aa6fbb4588b9': {
      message: 'Confirm bankruptcy discharge date',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '99735aca-8fce-4c19-8e74-0ff3407e624a': {
      message: 'Date of discharge must be after the date of bankruptcy',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '0ec4d531-826e-4591-8a75-809cfc5ddb7': {
      message: 'Confirm the date when DMP was registered',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'c2482c8b-6f38-4df8-aedd-a4376a5ec1a0': {
      message: 'Confirm if Client met the repayment requirements of their DMP',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '4e66a2e1-7af0-4255-b2a7-7224b449c30e': {
      message: 'Confirm the date when DMP was satisfied',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '3e647cab-40ff-46d3-b9ba-2205d18bb782': {
      message: 'Confirm DMP monthly repayment amount',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '9c1cda01-77b6-43e8-995a-cc9b52413ebc': {
      message: 'DMP date completed must be after date registered',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'e0b3e204-c672-4176-b10d-042d28147625': {
      message: 'Confirm date when IVA was registered',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '90f468f9-33dc-4e8f-9118-bb54a347fc2a': {
      message: 'Confirm date when IVA was completed',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '263b4b00-83a9-4491-87e1-fb4dde10d1fa': {
      message: 'Confirm IVA monthly repayment amount',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'da1fb103-95a5-4503-bd37-4233c67bee2c': {
      message: 'Confirm if Client met the repayment requirements of their IVA',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '8fead52d-6246-4a1d-9a17-5edfb91cb28a': {
      message: 'IVA date completed must be after date registered',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '3f955f9e-2457-419a-80c4-4a6242e39fe8': {
      message: 'Confirm the date when CCJ was registered',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '8f6cefda-67dd-4e10-aea4-89ef01478e32': {
      message: 'Confirm the date when CCJ was satisfied',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'df7577cb-3e9a-43f4-8ded-399ddef636c5': {
      message: 'Confirm CCJ creditor name',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'ed3742bf-b510-4f32-84bc-1bfe2ce5b293': {
      message: 'Confirm CCJ court name',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '063cd64f-be75-4997-8414-a4b81f3dcbb3': {
      message: 'Date CCJ registered must be before date CCJ satisfied',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'f4787a82-28b8-445b-9eca-fb9195d7026a': {
      message: 'Add account type for bank account',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    'f7ec2acf-2249-49ee-a116-c7c2624079db': {
      message: 'Add sort code for current account',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    'df59edf0-66d8-4af6-82f3-2459562e5973': {
      message: 'Add account number for current account',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    'fb66f1f8-9472-41ce-9bbd-4656768af686': {
      message: 'Add current account details',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '5ebf5d84-72cb-4bf8-a8af-4ef75dbffb2c': {
      message: 'Confirm the date of credit refusal',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '33e2aeff-de87-45e4-aa79-e6551edc82f7': {
      message: 'Confirm the name of credit refusal creditor',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'a1bafe5d-66ec-41f1-a3e1-45089b1d5a48': {
      message: 'Confirm credit refusal type',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '54fb2f5e-5ca5-4e83-8102-9e59db2d3e9e': {
      message: 'Confirm credit refusal details',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '08f39667-f20d-4a6e-a0fb-3543be78e0f4': {
      message: 'Confirm commitment type on debt',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '54eb2116-7636-4e7b-a139-47703cc42b29': {
      message: 'Confirm amount outstanding on debt',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '23df27b2-fee9-481d-9028-8b358062254d': {
      message: 'Confirm creditor name on debt',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '2216a94d-c818-446f-9084-88e842602e4c': {
      message: 'Confirm payment status on debt',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '444194b7-c458-48dd-987f-35d17bf3ebcb': {
      message: 'Confirm monthly repayment amount on debt',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'ad7fc981-c28f-4b6b-93c1-b414790bdea3': {
      message: 'Confirm final repayment date on debt',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'eabfeb16-5736-41a9-9e9e-99bee4d67366': {
      message: 'Confirm if debt is repaid each month',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'a4e4334a-3749-48d2-90fc-651a1658dd4f': {
      message: 'eIDV Outstanding for client ',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/id-verification',
    },
    '25c29e20-1464-48ad-abe3-1ab928c963c1': {
      message: "Please upload client's proof of ID",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/id-verification',
    },
    'd63c028e-3774-4c08-b64a-58040884bffe': {
      message: "Please verify client's Proof of Address",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/id-verification',
    },
    'bd6b7735-0df8-4f3c-9887-d3846c5bec75': {
      message: "Please verify client's proof of ID",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/id-verification',
    },
    '423145aa-39f6-4bb7-be47-79ba6267da87': {
      message: "Please upload client's Proof of Address",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/id-verification',
    },
    '01be36c4-b1ae-475f-8f89-f83196139323': {
      message: 'Product rate details incomplete for mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '8be14420-e7d4-4736-ac21-0bf01c00da6f': {
      message: 'Product rate details missing for mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '647935f0-96e0-4d9c-84d0-ce18309fdb94': {
      message: 'Set clients monthly other mortgage/rent outgoings',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '46f05799-a5ec-405f-835b-5de2189731d0': {
      message: "Set client's monthly maintenance outgoings",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '7341e3d5-627a-4e57-a0c3-39b3cd697f22': {
      message: "Set client's monthly fuel outgoings",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '37bcbf58-f37e-432d-b3e1-207c0e2190e5': {
      message: "Set client's monthly water outgoings",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'd5f469f4-79ef-4341-b4c5-a80e12900fa5': {
      message: "Set client's monthly communication outgoings",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '15e205ba-022a-44c2-b92c-e86c21e00595': {
      message: "Set client's monthly TV license outgoings",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'cb6a4dad-eae4-4d3b-886d-5b9ee5a432e2': {
      message: "Set client's monthly council tax outgoings",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '06a14ce7-cf4e-4cda-998a-f5b16bed3cbe': {
      message: "Set client's monthly car related outgoings",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    'b642cda9-4c61-455b-82d2-94818009c6b1': {
      message: "Set client's monthly transport outgoings",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '2f129eff-cb01-4915-a1d2-375b46fe3fce': {
      message: "Set client's monthly ground rent/service charge outgoings",
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/outgoings',
    },
    '5d76f44b-519f-4396-9f13-ac169aa9a3b6': {
      message: 'Deliver missing initial disclosure document',
      action: Action.IDD,
    },
    '7cc94df7-1f70-4e23-899b-3807dab4d990': {
      message: 'Client requires a valid email address',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '5726802b-92ce-4914-8e15-7c377d7464e4': {
      message: 'Client requires a valid Home or Mobile phone number',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
    '55b7fa41-737a-4304-a347-fe6809235a05': {
      message: 'Specify if mortgage is first or second charge',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/products',
    },
    'f86ce4c9-6c7b-46fc-91fc-95d10007770a': {
      message: 'Confirm if mortgage is portable',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/products',
    },
    '6a06e6de-80f0-4a24-9ef7-7e4e0bb362a3': {
      message: 'Add max LTV available to mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/products',
    },
    '5aef1eb8-a3ac-4de2-8da6-4b557be31afb': {
      message: 'Add monthly payment to mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/products',
    },
    '133deeee-7073-448b-b59b-96d2f74764cc': {
      message: 'Add revert monthly payment to mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/products',
    },
    'f8f3113c-de51-424b-832b-537fa1b17bec': {
      message: 'Add repayment amount to mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/products',
    },
    '776cc14f-a3e2-472b-9951-85d7f5b8526c': {
      message: 'Add interest-only amount to mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/products',
    },
    '6116956a-024a-493d-b8b9-75aba601714d': {
      message: 'Add proc fee to mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/products',
    },
    '640acdc7-3d04-4498-b17a-7f7a8a8170a2': {
      message: 'Add ERC to mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/details/products',
    },
    '983cad50-00af-4da3-ae98-fce734657518': {
      message: 'Add ERC to mortgage',
      action: Action.Redirect,
      pathname: '/cases/:case_id/products',
    },
    '64c4e94e-867f-45d7-bc6b-5101b7993ae9': {
      message: 'Upload mortgage application',
      action: Action.Redirect,
      pathname: '/cases/:case_id/documents',
    },
    'a04b8931-39e2-4507-b80e-eb08ce6cc83d': {
      message: 'Upload mortgage offer letter',
      action: Action.Redirect,
      pathname: '/cases/:case_id/documents',
    },
    '7260499e-c443-490a-9cd8-513994c774a1': {
      message: 'Add bankruptcy date',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'd6400178-4613-45e0-b785-3374078c54ff3': {
      message: 'Add CCJ amount',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    'fbe4cea5-8bca-4b01-aa8c-79a270bb01e3': {
      message: 'Add bankruptcy court name',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/credit',
    },
    '254e4ee9-5208-455a-aa20-984381a0ace8': {
      message: 'Right to Buy Flag present on case. Compliance Review may be required',
    },
    'ca4bd103-5c26-4481-9b63-df7956aeb40d': {
      message: 'Lending into Retirement Flag present on case. Compliance Review may be required',
    },
    'd2391928-167f-41af-b341-8c94bf6986db': {
      message: 'Redemption Penalty flag present on case. Compliance Review may be required',
    },
    'd50a93cf-61d9-48db-907b-72e5b8f02c78': {
      message: 'Equity Release Flag present on case. Compliance Review may be required',
    },
    'ecadd111-8a71-4330-8685-cbdefe8ee139': {
      message: 'Residential Interest Only Flag present on case. Compliance Review may be required',
    },
    'a8547e0a-8b80-4381-bf5e-597bf94cbd86': {
      message: '2nd Job Flag present on case. Compliance Review may be required',
    },
    '96b433a3-f60a-4d75-b5d2-5d77ad7ee613': {
      message: 'Gifted Deposit Flag present on case. Compliance Review may be required',
    },
    '714566b1-bef4-4011-bba1-e7ee8ec1c257': {
      message: 'Vulnerable Client Flag present on case. Compliance Review may be required',
    },
    '7236b9b0-d9b2-40ce-a7ed-26bfe6921b56': {
      message: 'Please confirm your clients Will status',
      action: Action.Redirect,
      pathname: '/cases/:case_id/fact-find/:client_id/personal-info',
    },
  }
}
