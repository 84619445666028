import React from 'react'

import { testHandle } from '@acre/utils'

import { ColourCardTypes, ItemCardData } from '../..'
import { defaultValue } from './ColouredItemCard.helpers'
import { Key, ListItemWrapper, Value } from './ColouredItemCard.styles'

type Props = {
  data?: ItemCardData
  colourVariant?: ColourCardTypes
  id?: string
}

const ListItem = ({ data, colourVariant, id }: Props) => {
  if (!data?.label && !data?.value) {
    return null
  }

  return (
    <ListItemWrapper colourVariant={colourVariant} data-testid={testHandle('ItemCardListItem')}>
      <Key colourVariant={colourVariant}>{data?.label || defaultValue}</Key>
      <Value data-testid={id ? testHandle(id) : null} colourVariant={colourVariant}>
        {data?.value || defaultValue}
      </Value>
    </ListItemWrapper>
  )
}

export default ListItem
